import React, { Fragment } from "react";

export function AvailabilityConfirmation(
  transaction: any,
  user: any,
  acceptLoading: boolean,
  handlePropertyAvailability: (payload: "available" | "unavailable") => void,
  handleReconfirmAvailability: (payload: "available" | "unavailable") => void
): React.ReactNode {
  return (
    <Fragment>
      <div className="text-secondary fs-14">
        {transaction.renter?.id === user?.id ? (
          <p className="alert alert-warning" role="alert">
            Your interest has been communicated to the property owner. We are
            waiting for a confirmation on the availability of the property
          </p>
        ) : transaction.status === "on-hold" ? (
          <p className="alert alert-danger" role="alert">
            Is this apartment still available for booking?
          </p>
        ) : (
          <p className="alert alert-danger" role="alert">
            Chat is currently unavailable. Please confirm the availabilty of the
            property first. Is this lisitng currently available ?
          </p>
        )}
      </div>
      {transaction?.owner?.id === user?.id &&
        (transaction.status === "on-hold" ? (
          <div>
            <div className="d-grid gap-2">
              <button
                className="btn btn-primary rounded-pill px-4 py-3"
                disabled={acceptLoading}
                onClick={() => handleReconfirmAvailability("available")}
              >
                {acceptLoading ? (
                  <span className="spinner-border spinner-border-sm"></span>
                ) : (
                  "Available"
                )}
              </button>
              <button
                className="btn btn-outline rounded-pill px-4 py-3"
                disabled={acceptLoading}
                onClick={() => handleReconfirmAvailability("unavailable")}
              >
                {acceptLoading ? (
                  <span className="spinner-border spinner-border-sm"></span>
                ) : (
                  "Unavailable"
                )}
              </button>
            </div>
          </div>
        ) : (
          <div className="d-grid gap-2">
            <button
              className="btn btn-primary rounded-pill px-4 py-3"
              disabled={acceptLoading}
              onClick={() => handlePropertyAvailability("available")}
            >
              {acceptLoading ? (
                <span className="spinner-border spinner-border-sm"></span>
              ) : (
                "Yes"
              )}
            </button>
            <button
              className="btn btn-outline rounded-pill px-4 py-3"
              disabled={acceptLoading}
              onClick={() => handlePropertyAvailability("unavailable")}
            >
              {acceptLoading ? (
                <span className="spinner-border spinner-border-sm"></span>
              ) : (
                "No"
              )}
            </button>
          </div>
        ))}
    </Fragment>
  );
}
