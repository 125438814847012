import { DeveloperDashboardLayout } from "@layouts/DeveloperDashboardLayout";
import React, { useEffect, useState } from "react";
import ForumSideMenu from "./forum-flow/forum-side";
import ForumMainMenu from "./forum-flow/forum-main";
import { getAllForum, getAllPostInForum } from "@services/krentDeveloper";

export const DevMessaging = () => {
  const [forumData, setForumData] = useState([]);
  const [selectedForumId, setSelectedForumId] = useState<string | null>(null);
  const [selectedForum, setSelectedForum] = useState<string | null>(null);

  const [posts, setPosts] = useState([]);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    const controller = new AbortController();
    const fetchData = async () => {
      try {
        const data = await getAllForum(controller.signal);
        setForumData(data);

        // Automatically select the first forum if available
        if (data.length > 0) {
          setSelectedForumId(data[0]._id);
          setSelectedForum(data[0]);
        }
      } catch (err) {
        if (err !== "AbortError") {
          setError(err as Error);
        }
      }
    };

    fetchData();

    return () => {
      controller.abort();
    };
  }, []);

  useEffect(() => {
    const controller = new AbortController();

    if (selectedForumId) {
      const fetchPosts = async () => {
        try {
          const data = await getAllPostInForum(
            selectedForumId,
            controller.signal
          );
          setPosts(data);
        } catch (err) {
          if (err !== "AbortError") {
            setError(err as Error);
          }
        }
      };
      fetchPosts();
    }

    return () => {
      controller.abort();
    };
  }, [selectedForumId]);
  console.log(error);
  const refetchPosts = async () => {
    if (selectedForumId) {
      const controller = new AbortController();
      try {
        const data = await getAllPostInForum(
          selectedForumId,
          controller.signal
        );
        setPosts(data);
      } catch (err) {
        if (err !== "AbortError") {
          setError(err as Error);
        }
      }
    }
  };

  return (
    <DeveloperDashboardLayout title="Forum">
      <div className="container-fluid vh-100 d-flex flex-column">
        <div className="row mb-3">
          <div className="col-12">
            <div className="d-flex align-items-center">
              <p>Home</p>
              <i className="fa-light fa-angle-right mx-2"></i>
              <p>Dashboard</p>
              <i className="fa-light fa-angle-right mx-2"></i>
              <p className="text-primary">Forum</p>
            </div>
          </div>
        </div>
        <div className="row flex-grow-1 overflow-hidden">
          <div className="col-4 h-100">
            <ForumSideMenu
              forumData={forumData}
              onSelectForum={setSelectedForumId}
              selectedForumId={selectedForumId}
            />
          </div>
          <div className="col-8 h-100">
            <ForumMainMenu
              posts={posts}
              forumId={selectedForumId}
              fetchPosts={refetchPosts}
              selectedForum={selectedForum}
            />
          </div>
        </div>
      </div>
    </DeveloperDashboardLayout>
  );
};
