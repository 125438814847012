import { useState } from "react";
import { useNavigate } from "react-router-dom";

import { OnboardingLayout } from "@layouts/OnboardingLayout";
import { PrimaryButton } from "@shared/buttons";
import Logo from "@shared/Logo";
import { Modal } from "@shared/controls/Modal";
import GetMeta from "@pages/SeoPages/GetMeta";

export const AccountSelection = () => {
  const [type, setType] = useState("");
  const [showModal, setShowModal] = useState(false);

  const navigate = useNavigate();

  const handleBack = () => {
    setShowModal(false);
    setType("");
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    if (showModal) {
      if (type === "seller" || type === "agent") {
        setShowModal(false);
        navigate(`/get-started/${type}`);
        return;
      }
    }
    navigate(`/get-started/${type}`);
  };

  return (
    <OnboardingLayout>
      <GetMeta
        description="Start your journey to home ownership or selling success! connect with our expert agents. Take the first step towards your real estate goals."
        title={`Get Started | Krent Inc`}
        canonicalValue={"terms"}
      />
      <div className="container">
        <h3
          className="text-md-start fw-bolder mb-3 text-link text-black mt-3"
          onClick={() => navigate("/")}
        >
          <Logo />
        </h3>
        <h4 className="fw-bolder">Select account type</h4>
        <p className="text-secondary fs-14 mb-3">
          Let us know what you’ll be using Krent for so we can optimize your
          experience.
        </p>

        <form className="mb-4 mt-4 mt-md-0" onSubmit={handleSubmit}>
          <div className="mb-5">
            <div className="row g-0 align-items-start mb-2">
              <div className="col-1 form-check">
                <input
                  className="form-check-input cursor-pointer"
                  name="account-type"
                  id="seller"
                  type="radio"
                  checked={type === "seller" || type === "agent"}
                  onChange={() => setShowModal(true)}
                />
              </div>
              <div className="col-11">
                <h6 className="fw-bold">Seller Account</h6>
                <p className="text-secondary fs-14 m-0">
                  I’m looking to sell properties and rent out apartments on
                  Krent.
                </p>
              </div>
            </div>

            <hr className="mb-2" />

            <div className="row g-0 align-items-start">
              <div className="col-1 form-check">
                <input
                  className="form-check-input cursor-pointer"
                  name="account-type"
                  id="buyer"
                  type="radio"
                  onChange={() => setType("buyer")}
                />
              </div>
              <div className="col-11">
                <h6 className="fw-bold">Buyer Account</h6>
                <p className="text-secondary fs-14 m-0">
                  I’m looking to rent or buy properties and apartments on Krent.
                </p>
              </div>
            </div>

            <hr className="mb-2 hidden" />

            <div className="row g-0 align-items-start mb-5 hidden">
              <div className="col-1 form-check">
                <input
                  className="form-check-input cursor-pointer"
                  name="account-type"
                  id="developer"
                  type="radio"
                  onChange={() => setType("developer")}
                />
              </div>
              <div className="col-11">
                <h6 className="fw-bold">Developer Account</h6>
                <p className="text-secondary fs-14 m-0">
                  I'm looking to develop new properties and manage real estate
                  projects on Krent.
                </p>
              </div>
            </div>
          </div>

          {/* <hr className='mb-2' /> */}

          {/* <div className='row g-0 align-items-start mb-5'>
            <div className='col-1 form-check'>
              <input
                className='form-check-input cursor-pointer'
                name='account-type'
                id='buyer'
                type='radio'
                onChange={() => setType('agent')}
              />
            </div>
            <div className='col-11'>
              <h6 className='fw-bold'>Agent Account</h6>
              <p className='text-secondary fs-14 m-0'>I’m a property agent.</p>
            </div>
          </div> */}

          {/* <hr className='mb-2' /> */}

          {/* <div className='row g-0 align-items-start mb-2'>
						<div className='col-1 form-check'>
							<input
								className='form-check-input'
								name='account-type'
								id='buyer'
								type='radio'
								onChange={() => setType('property-manager')}
							/>
						</div>
						<div className='col-11'>
							<h6 className='fw-bold'>Property Manager Account</h6>
							<p className='text-secondary fs-14 m-0'>
								I’m a property manager.
							</p>
						</div>
					</div> */}

          <PrimaryButton type="primary" text="Continue" width="50%" />
        </form>
        {showModal && (
          <Modal
            show={showModal}
            onClose={() => setShowModal(false)}
            dismissible={false}
          >
            <div className="col px-4">
              <h6 className="fw-bold fs-20">
                Are you an Agent or Property Owner?
              </h6>

              <div className="row g-0 align-items-start mt-5 mb-3">
                <div className="col-1 form-check">
                  <input
                    className="form-check-input cursor-pointer"
                    name="account-type"
                    id="seller"
                    type="radio"
                    onChange={() => setType("agent")}
                  />
                </div>
                <div className="col-11">
                  <h6 className="fw-bold">Agent</h6>
                </div>
              </div>
              <div className="row g-0 align-items-start mb-5">
                <div className="col-1 form-check">
                  <input
                    className="form-check-input cursor-pointer"
                    name="account-type"
                    id="seller"
                    type="radio"
                    onChange={() => setType("seller")}
                  />
                </div>
                <div className="col-11">
                  <h6 className="fw-bold">Property Owner</h6>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <PrimaryButton
                    text="Continue"
                    type="primary"
                    click={handleSubmit}
                  />
                </div>
                <div className="col-12">
                  <PrimaryButton
                    text="Go Back"
                    type="outline"
                    click={handleBack}
                  />
                </div>
              </div>
            </div>
          </Modal>
        )}
      </div>
    </OnboardingLayout>
  );
};
