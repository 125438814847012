import { formatCurrency } from "@utils/currency";
import { formatDateRange } from "@utils/helpers";
import React from "react";

interface DateRange {
  start: Date;
  end: Date;
}

interface PeakPeriodProps {
  normalDays: number;
  normalDateRanges: DateRange[];
  peakDays: number;
  peakDateRanges: DateRange[];
  peakPrice: number;
  cautionFee?: string | number;
  currency: string;
  surgeDays?: { date: string; price: number }[];
  surgeDateRanges?: DateRange[];
  surgePrice?: number;
  mainNormalPrice?: number;
  normalPrice?: number;
}

const PeakPeriod: React.FC<PeakPeriodProps> = ({
  normalDays,
  normalDateRanges,
  peakDays,
  peakDateRanges,
  peakPrice,
  cautionFee,
  currency,
  surgeDays,
  // surgeDateRanges,
  surgePrice,
  mainNormalPrice,
}) => {
  const totalDays = normalDays + peakDays + (surgeDays?.length || 0);
  const totalPrice =
    (Number(mainNormalPrice) || 0) * normalDays +
    peakPrice +
    (surgePrice || 0) +
    (Number(cautionFee) || 0);
  const normalTotalPrice =
    (Number(mainNormalPrice) || 0) * normalDays + peakPrice + (surgePrice || 0);
  console.log(totalDays);
  return (
    <div>
      <div className="d-flex justify-content-between text-center gap-1">
        <h6 className="text-gray-600 mb-1">Days</h6>
        <h6 className="text-gray-600 mb-1">Date ranges</h6>
        <h6 className="text-gray-600 mb-1">Total price</h6>
      </div>

      <div className="d-flex justify-content-between gap-1 my-2 scrollable-range">
        <p className="fs-12 m-0">
          {totalDays} {totalDays > 1 ? "days" : "day"}
        </p>
        <div className="flex flex-col space-y-1">
          {normalDateRanges.map((range, index) => (
            <div key={`normal-${index}`} className="fs-12">
              {formatDateRange(range)}
            </div>
          ))}
          {peakDateRanges.map((range, index) => (
            <div key={`peak-${index}`} className="fs-12">
              {formatDateRange(range)}
            </div>
          ))}
          {/* {surgeDateRanges?.map((range, index) => (
            <div key={`surge-${index}`} className="fs-12">
              {formatDateRangeIncrement(range)}
            </div>
          ))} */}
        </div>
        <p className="fs-12 m-0">
          {currency}
          {formatCurrency(normalTotalPrice)}
        </p>
      </div>

      {cautionFee && (
        <div className="d-flex justify-content-between gap-1 my-2 scrollable-range">
          <p className="fs-12 m-0">Caution fee</p>
          <p className="fs-12 m-0">
            {currency}
            {formatCurrency(Number(cautionFee))}
          </p>
        </div>
      )}

      <div className="d-flex justify-content-between my-2 scrollable-range">
        <h5>Total price</h5>
        <h5>
          {currency}
          {formatCurrency(totalPrice)}
        </h5>
      </div>
    </div>
  );
};

export default PeakPeriod;
