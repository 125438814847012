import React, { useRef, useState } from 'react'

interface StepIndicatorProps {
  currentStep: number
  totalSteps: number
}

const StepIndicator: React.FC<StepIndicatorProps> = ({
  currentStep,
  totalSteps,
}) => {
  return (
    <div
      className="stepper-container"
      style={
        {
          '--total-steps': totalSteps,
          '--current-step': currentStep,
        } as React.CSSProperties
      }
    >
      {Array.from({ length: totalSteps }, (_, index) => (
        <div
          key={index}
          className={`step-item ${index + 1 <= currentStep ? 'active' : ''}`}
        >
          <div className="step-number">{index + 1}</div>
          {index < totalSteps - 1 && <div className="step-line"></div>}
        </div>
      ))}
    </div>
  )
}

interface MultiStepFormProps {
  steps: React.ReactNode[]
  handleSubmit: () => void
  onStepSubmit?: (currentStep: number) => Promise<boolean>
  isCurrentStepValid?: boolean // Add this line
}

const MultiStepForm: React.FC<MultiStepFormProps> = ({
  steps,
  handleSubmit,
  onStepSubmit,
  isCurrentStepValid,
}) => {
  const [currentStep, setCurrentStep] = useState(1)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const stepRefs = useRef<any[]>([])

  const nextStep = async () => {
    setIsSubmitting(true)

    try {
      const currentStepRef = stepRefs.current[currentStep - 1]

      if (currentStepRef?.validate) {
        const isValid = await currentStepRef.validate()
        if (!isValid) {
          setIsSubmitting(false)
          return
        }
      }

      if (currentStep < steps.length) {
        if (onStepSubmit) {
          const canProceed = await onStepSubmit(currentStep)
          if (canProceed) {
            setCurrentStep(currentStep + 1)
          }
        } else {
          setCurrentStep(currentStep + 1)
        }
      } else {
        handleSubmit()
      }
    } finally {
      setIsSubmitting(false)
    }
  }

  const prevStep = () => {
    if (currentStep > 1) {
      setCurrentStep(currentStep - 1)
    }
  }

  // Clone each step element to attach the ref
  const stepsWithRefs = steps.map((step, index) =>
    React.cloneElement(step as React.ReactElement, {
      ref: (ref: any) => (stepRefs.current[index] = ref),
    }),
  )

  return (
    <div className="max-w-2xl mx-auto p-6">
      <StepIndicator currentStep={currentStep} totalSteps={steps.length} />
      <div className="mb-6">{stepsWithRefs[currentStep - 1]}</div>
      <div className="flex justify-between w-max ml-auto gap-4">
        <button
          onClick={prevStep}
          disabled={currentStep === 1}
          className="py-2 px-4 fw-bold rounded-50 flex gap-2 items-center bg-white text-primary primary-border"
        >
          <i className="fa fa-arrow-left text-white fa-solid rounded-full p-1 bg-primary"></i>
          Previous
        </button>
        <button
          onClick={nextStep}
          disabled={isSubmitting || !isCurrentStepValid}
          className="bg-primary py-2 px-4 fw-bold text-white rounded-50 flex gap-2 items-center"
        >
          {currentStep === steps.length ? 'Submit' : 'Next'}
          <i className="fa-arrow-right fa-solid fa text-primary bg-white rounded-full p-1"></i>
        </button>
      </div>
    </div>
  )
}

export default MultiStepForm
