import { fetchCookie } from "./cookies";

export const isEmpty = (object: object | null): boolean =>
  object === null ||
  Object.keys(object).length <= 0 ||
  Object.values(object).every((x) => x === null || x === "");

export function separateFullName(fullName: string) {
  const nameParts = fullName.trim().split(" ");
  const firstName = nameParts[0];
  const lastName = nameParts.slice(1).join(" ");

  return {
    firstName,
    lastName,
  };
}

export const addPageToSessionStorage = (page: number) => {
  sessionStorage.setItem("propertyListPage", JSON.stringify(page));
};
export const getPageFromSessionStorage = () => {
  let pageString = sessionStorage.getItem("propertyListPage");
  if (pageString) {
    JSON.parse(pageString);
  } else {
    pageString = "1";
  }
  return pageString;
};

export const phoneNumberCheck =
  /(\+?\d{1,4}[\s.-]?[a-zA-Z0-9]{1,3}[\s.-]?[a-zA-Z0-9]{1,4}[\s.-]?[a-zA-Z0-9]{1,4}[\s.-]?[a-zA-Z0-9]{1,9}|(?:zero|one|two|three|four|five|six|seven|eight|nine)[\s.-]*)+/g;

export const phoneNumberRequestCheck =
  /\b(?:call\s+me|phone\s*number|mobile\s*number|(?:can|may|could|would|please|might|should)\s*(?:you\s+)?(?:give|send|provide|share|tell|text|show)\s*(?:me\s+)?(?:your\s+|a\s+)?phone\s*number|number|digits|contact\s*number|cell\s*number|mobile\s*number)\b/gi;

export function numberWithCommas(x: string) {
  return x?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export const formatDate = (dateString: string) => {
  const date = new Date(dateString);
  const userTimezoneOffset = date.getTimezoneOffset() * 60000;
  return new Date(date.getTime() - userTimezoneOffset)
    .toISOString()
    .slice(0, 10);
};

export const formatDateRange = (range: any) => {
  const formatToDateString = (date: Date) => {
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };
  return `${formatToDateString(range.start)} - ${formatToDateString(
    range.end
  )}`;
};
export const formatDateRangeIncrement = (range: any) => {
  const formatToDateString = (date: Date) => {
    const newDate = new Date(date);
    newDate.setDate(newDate.getDate() + 1); // Add 1 day
    const day = newDate.getDate().toString().padStart(2, "0");
    const month = (newDate.getMonth() + 1).toString().padStart(2, "0");
    const year = newDate.getFullYear();
    return `${day}/${month}/${year}`;
  };

  return `${formatToDateString(range.start)} - ${formatToDateString(
    range.end
  )}`;
};

export const formatCurrency = (value: any) => {
  return value.toLocaleString(undefined, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
};
export const isAuthenticated = () => {
  return fetchCookie("1Q_SPA") ? true : false;
};

export const formatDateToTime = (isoDate: string): string => {
  const date = new Date(isoDate);
  const hours = date.getHours().toString().padStart(2, "0"); // Get hours and add leading zero if necessary
  const minutes = date.getMinutes().toString().padStart(2, "0"); // Get minutes and add leading zero if necessary
  return `${hours}:${minutes}`;
};
export const isValidDate = (date: Date | null, inspectionSchedules: any) => {
  if (!date) return false;
  if (!inspectionSchedules?.option1?.from || !inspectionSchedules?.option2?.to)
    return false;

  const option1Date = new Date(inspectionSchedules.option1.from);
  const option2Date = new Date(inspectionSchedules.option2.to);

  const normalizedDate = new Date(date);
  normalizedDate.setHours(0, 0, 0, 0);

  option1Date.setHours(0, 0, 0, 0);
  option2Date.setHours(0, 0, 0, 0);

  return (
    normalizedDate.getTime() === option1Date.getTime() ||
    normalizedDate.getTime() === option2Date.getTime()
  );
};
export const formatNumberWithCommas = (value: string | number): any => {
  const cleanValue = value?.toString().replace(/[^\d.]/g, "");

  const number = parseFloat(cleanValue);

  if (isNaN(number)) return "";

  return number.toLocaleString("en-US");
};
