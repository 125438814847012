import React, { useState } from "react";
import { Modal } from "react-bootstrap";

interface TimerProps {
  cancelBooking?: (transactionId: string) => void;
  // transactionId: string;
  transactionStatus: string;
  renterId: string;
  userId: string;
  // timeCustomerAccepted: string;
  timeLeft: number;
  transProof: boolean;
}

const Timer: React.FC<TimerProps> = ({
  transactionStatus,
  renterId,
  userId,
  timeLeft,
  transProof,
}) => {
  const [showModal, setShowModal] = useState(true);

  const closeModal = () => {
    setShowModal(false);
  };

  const formatTime = (seconds: number) => {
    const minutes = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${minutes}:${secs < 10 ? "0" : ""}${secs}`;
  };

  return (
    <>
      {renterId === userId && transactionStatus === "customer-accepted" && (
        <div
          className="bg-white position-fixed text-red fs-12 p-2"
          style={{ top: "90px" }}
        >
          {timeLeft > 0 ? (
            <p>
              Please note that you have <strong>{formatTime(timeLeft)}</strong>{" "}
              to make payment and upload your receipt. If payment and receipt
              are not received, your booking will be cancelled.
            </p>
          ) : (
            <p>Time is up! Your booking has been cancelled.</p>
          )}
        </div>
      )}

      <Modal
        show={
          showModal &&
          renterId === userId &&
          transactionStatus === "customer-accepted" &&
          !transProof
        }
        onHide={closeModal}
        centered
        backdrop="static"
      >
        <div className="p-3">
          <p>
            Please note that you have <strong>{formatTime(timeLeft)}</strong> to
            make payment and upload your receipt. If payment and receipt are not
            received, your booking will be cancelled.
          </p>
          <h3 className="text-red fw-bold fs-24 text-center">
            {formatTime(timeLeft)}
          </h3>
        </div>
        <div className="text-center">
          <button className="btn btn-primary mb-3" onClick={closeModal}>
            Close Modal
          </button>
        </div>
      </Modal>
    </>
  );
};

export default Timer;
