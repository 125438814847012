import React, { useState, useEffect } from "react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { format } from "date-fns";
import { Value } from "react-calendar/dist/cjs/shared/types";
import { getPropAvailabiltyCal } from "@services/krent.service";

interface ShortletCalendarProps {
  propertyId: string;
  onDurationSelect: (duration: [Date | null, Date | null]) => void;
  normalPrice: number;
}

const ShortletCalendar: React.FC<ShortletCalendarProps> = ({
  propertyId,
  onDurationSelect,
  normalPrice,
}) => {
  const [selectedRange, setSelectedRange] = useState<
    [Date | null, Date | null]
  >([null, null]);
  const [prices, setPrices] = useState<Record<string, number>>({});

  const [statusMap, setStatusMap] = useState<Record<string, string>>({});

  useEffect(() => {
    const fetchAvailability = async () => {
      try {
        const currentDate = new Date();
        const nextYearDate = new Date();
        nextYearDate.setFullYear(currentDate.getFullYear() + 1);

        const startDate = format(currentDate, "yyyy-MM-01");
        const endDate = format(nextYearDate, "yyyy-MM-01");

        const response: any = await getPropAvailabiltyCal(
          propertyId,
          startDate,
          endDate
        );

        if (response.status) {
          const priceMap: Record<string, number> = {};
          const statusMapData: Record<string, string> = {};

          response.data.forEach((item: any) => {
            let currentDate = new Date(item.startDate);
            const endDate = new Date(item.endDate);
            endDate.setDate(endDate.getDate() - 1);

            while (currentDate <= endDate) {
              const formattedDate = format(currentDate, "yyyy-MM-dd");

              priceMap[formattedDate] = item.price;
              if (item.status === "unavailable" || item.status === "booked") {
                statusMapData[formattedDate] = item.status;
              }

              currentDate.setDate(currentDate.getDate() + 1);
            }
          });

          setPrices(priceMap);
          setStatusMap(statusMapData);
        }
      } catch (error) {
        console.error("Error fetching availability data:", error);
      }
    };
    fetchAvailability();
  }, [propertyId]);
  const handleDateChange = (range: Value) => {
    if (
      Array.isArray(range) &&
      range.length === 2 &&
      range[0] instanceof Date &&
      range[1] instanceof Date
    ) {
      const checkInDate = new Date(
        range[0].getFullYear(),
        range[0].getMonth(),
        range[0].getDate(),
        12
      );

      const checkOutDate = new Date(
        range[1].getFullYear(),
        range[1].getMonth(),
        range[1].getDate() + 1,
        12
      );

      setSelectedRange([checkInDate, checkOutDate]);
      onDurationSelect([checkInDate, checkOutDate]);
    } else {
      setSelectedRange([null, null]);
      onDurationSelect([null, null]);
    }
  };

  const tileContent = ({ date }: { date: Date }) => {
    const dateString = format(date, "yyyy-MM-dd");
    const status = statusMap[dateString];
    const price = prices[dateString] || normalPrice;
    return (
      <div className="text-xs text-center font-semibold">
        <span
          className={`block text-${
            status === "booked"
              ? "blue"
              : status === "unavailable"
              ? "red"
              : "primary"
          } `}
        >
          ₦{price}
        </span>
        <br />
        {status && (
          <span
            className={`text-${status === "booked" ? "blue" : "red"} block`}
          >
            {status.charAt(0).toUpperCase() + status.slice(1)}
          </span>
        )}
      </div>
    );
  };

  const tileDisabled = ({ date }: { date: Date }) => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    return date < today;
  };

  return (
    <div className="max-w-lg mx-auto bg-white rounded-lg shadow-md">
      <h6 className="font-bold mb-4">Select Duration</h6>
      <Calendar
        selectRange
        onChange={handleDateChange}
        tileContent={tileContent}
        tileDisabled={tileDisabled}
        className="!text-black"
      />
      {selectedRange[0] && selectedRange[1] && (
        <p className="my-4 font-semibold">
          Selected Duration: <br />
          {format(selectedRange[0], "yyyy-MM-dd")} to{" "}
          {format(
            new Date(selectedRange[1]).setDate(
              new Date(selectedRange[1]).getDate() - 1
            ),
            "yyyy-MM-dd"
          )}
        </p>
      )}
    </div>
  );
};

export default ShortletCalendar;
