import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { addDays, format, subDays } from "date-fns";
import { getPropAvailabiltyCal } from "@services/krent.service";
import { DateRange } from "./PriceCard";
import { formatCurrency } from "@utils/currency";
import { toast } from "react-toastify";

interface BuyerCalPickerProps {
  type: "shortlet" | "long lease" | "sale";
  dateFrom: Date | null;
  setDateFrom: (date: Date | null) => void;
  dateTo: Date | null;
  setDateTo: (date: Date | null) => void;
  propertyId: string;
  surgeDays: { date: string; price: number }[];
  surgeDateRanges?: DateRange[];
  setSurgeData: (
    surgeDays: { date: string; price: number }[],
    surgeDateRanges: DateRange[]
  ) => void;
  normalPrice: number;
}

const BuyerCalPicker: React.FC<BuyerCalPickerProps> = ({
  type,
  dateFrom,
  setDateFrom,
  dateTo,
  setDateTo,
  propertyId,
  setSurgeData,
  normalPrice,
}) => {
  const [prices, setPrices] = useState<Record<string, number>>({});
  const [unavailableDates, setUnavailableDates] = useState<string[]>([]);

  useEffect(() => {
    const fetchAvailability = async () => {
      try {
        const currentDate = new Date();
        const nextYearDate = new Date();
        nextYearDate.setFullYear(currentDate.getFullYear() + 1);

        const startDate = format(currentDate, "yyyy-MM-01");
        const endDate = format(nextYearDate, "yyyy-MM-01");

        const response: any = await getPropAvailabiltyCal(
          propertyId,
          startDate,
          endDate
        );

        if (response?.status && Array.isArray(response.data)) {
          const priceMap: Record<string, number> = {};
          const surgeDaysWithPrices: { date: string; price: number }[] = [];
          const surgeDateRanges: DateRange[] = [];
          const unavailableDays: string[] = [];

          response.data.forEach((item: any) => {
            let currentDate = new Date(item.startDate);
            const endDate = subDays(new Date(item.endDate), 1);

            while (currentDate <= endDate) {
              const formattedDate = format(currentDate, "yyyy-MM-dd");
              let price = item.price || normalPrice;

              priceMap[formattedDate] = price;

              if (item.status === "unavailable" || item.status === "booked") {
                unavailableDays.push(formattedDate);
              }

              if (item.status === "available" && item.price) {
                surgeDaysWithPrices.push({
                  date: formattedDate,
                  price: item.price,
                });
              }

              currentDate.setDate(currentDate.getDate() + 1);
            }

            if (item.price) {
              surgeDateRanges.push({
                start: new Date(item.startDate),
                end: subDays(new Date(item.endDate), 1),
              });
            }
          });

          if (dateFrom && dateTo) {
            const formattedDateFrom = format(dateFrom, "yyyy-MM-dd");
            const formattedDateTo = format(dateTo, "yyyy-MM-dd");

            const filteredSurgeDays = surgeDaysWithPrices.filter(
              (item) =>
                item.date >= formattedDateFrom && item.date <= formattedDateTo
            );

            const filteredSurgeDateRanges = surgeDateRanges.filter(
              ({ start, end }) =>
                format(start, "yyyy-MM-dd") <= formattedDateTo &&
                format(end, "yyyy-MM-dd") >= formattedDateFrom
            );

            setSurgeData(filteredSurgeDays, filteredSurgeDateRanges);
          } else {
            setSurgeData(surgeDaysWithPrices, surgeDateRanges);
          }

          setPrices(priceMap);
          setUnavailableDates(unavailableDays);
        }
      } catch (error) {
        console.error("Error fetching availability data:", error);
      }
    };

    if (propertyId) fetchAvailability();
  }, [propertyId, dateFrom, dateTo]);

  const handleDateFromChange = (date: Date | null) => {
    setDateFrom(date);
    if (!date) return;

    if (type === "long lease") {
      const oneYearLater = addDays(date, 365);
      setDateTo(oneYearLater);
    } else if (dateTo && dateTo <= date) {
      setDateTo(null);
    }
  };

  const handleDateToChange = (date: Date | null) => {
    if (!dateFrom || !date) return;

    let isInvalidSelection = false;
    let tempDate = new Date(dateFrom);
    tempDate.setDate(tempDate.getDate() + 1);

    while (tempDate <= date) {
      const formattedDate = format(tempDate, "yyyy-MM-dd");

      if (
        unavailableDates.includes(formattedDate) &&
        formattedDate !== format(new Date(dateFrom), "yyyy-MM-dd") &&
        formattedDate !== format(date, "yyyy-MM-dd")
      ) {
        isInvalidSelection = true;
        break;
      }

      tempDate.setDate(tempDate.getDate() + 1);
    }

    if (type === "shortlet" && isInvalidSelection) {
      toast.error("You cannot select across unavailable dates.");
      return;
    }

    setDateTo(date);
  };

  const renderDayContents = (day: number, date: Date) => {
    const dateString = format(date, "yyyy-MM-dd");
    const displayPrice = prices[dateString] ? prices[dateString] : normalPrice;

    return (
      <div style={{ textAlign: "center" }}>
        <div style={{ fontSize: "9px", color: "green" }}>
          ₦{formatCurrency(displayPrice)}
        </div>
        <span>{day}</span>
      </div>
    );
  };

  return (
    <div>
      {/* Date From Input */}
      {type !== "sale" && (
        <div className="form-group m-1">
          <label className="form-label fs-14">
            {type === "shortlet" ? "Check In" : "From"}
          </label>
          <div className="input-group">
            <span className="input-group-text input-group-text-0">
              <i className="iconly-Calendar"></i>
            </span>
            <div className="form-control form-control-0 border-start-0">
              <DatePicker
                selected={dateFrom}
                onChange={handleDateFromChange}
                placeholderText="dd-mm-yyyy"
                className="form-control form-control-0 border-0"
                minDate={new Date()}
                showYearDropdown
                showMonthDropdown
                dateFormat="dd-MM-yyyy"
                renderDayContents={renderDayContents}
                excludeDates={
                  type === "shortlet"
                    ? unavailableDates.map((date) => new Date(date))
                    : undefined
                }
              />
            </div>
          </div>
        </div>
      )}

      {/* Date To Input */}
      {type !== "sale" && (
        <div className="form-group mb-3">
          <label className="form-label fs-14">
            {type === "shortlet" ? "Check Out" : "To"}
          </label>
          <div className="input-group">
            <span className="input-group-text input-group-text-0">
              <i className="iconly-Calendar"></i>
            </span>
            <div className="form-control form-control-0 border-start-0">
              <DatePicker
                selected={dateTo}
                onChange={handleDateToChange}
                placeholderText="dd-mm-yyyy"
                className="form-control form-control-0 border-0"
                minDate={dateFrom ? addDays(new Date(dateFrom), 1) : null}
                showYearDropdown
                showMonthDropdown
                disabled={!dateFrom || type === "long lease"}
                dateFormat="dd-MM-yyyy"
                renderDayContents={renderDayContents}
                excludeDates={
                  type === "shortlet"
                    ? unavailableDates
                        .map((date) => new Date(date))
                        .filter((date) => {
                          if (dateFrom) {
                            const formattedDateFrom = format(
                              dateFrom,
                              "yyyy-MM-dd"
                            );
                            const firstUnavailable = unavailableDates.find(
                              (d) => d > formattedDateFrom
                            );

                            if (
                              firstUnavailable &&
                              format(date, "yyyy-MM-dd") === firstUnavailable
                            ) {
                              return false;
                            }
                          }
                          return (
                            date > (dateFrom ? new Date(dateFrom) : new Date())
                          );
                        })
                    : undefined
                }
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default BuyerCalPicker;
