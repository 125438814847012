import { useRef, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { PropertyKind } from "@interfaces/properties.interface";
import { searchPropertiesByKeyword } from "@services/krent.service";
import { addPageToSessionStorage } from "@utils/helpers";
import CustomDatePicker from "./DatePicker";

type Props = {
  location?: string;
  propertySize?: string;
  price?: string;
  propertyTypes?: PropertyKind[];
  status?: string;
  intent?: string | null;
  maxPprice?: any;
  extraClass?: string;
  setPropertyIntent?: any;
  sizeClass: string;
  page?: number;
  setPage?: (param: number) => void;
};

export const SearchCard2: React.FC<Props> = ({
  sizeClass,
  extraClass,
  setPage,
}: Props) => {
  const navigate = useNavigate();

  const [keyWord, setKeyWord] = useState<string>("");
  const [keyWordLoading, setKeyWordLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>("");
  const prevKeyWord = useRef<string>("");
  const [checkInDate, setCheckInDate] = useState<Date | null>(null);
  const [checkOutDate, setCheckOutDate] = useState<Date | null>(null);

  const onSearchKeyWord = async () => {
    if (!keyWord && !checkInDate && !checkOutDate) {
      navigate("/properties/");
      return;
    }

    setKeyWordLoading(true);
    try {
      const startDate = checkInDate
        ? checkInDate.toISOString().split("T")[0]
        : undefined;
      const endDate = checkOutDate
        ? checkOutDate.toISOString().split("T")[0]
        : undefined;

      const result = await searchPropertiesByKeyword(
        keyWord || "",
        startDate || "",
        endDate || ""
      );

      if (result?.data?.length > 0) {
        setKeyWord("");
        setError("");
        if (setPage) setPage(1);
        addPageToSessionStorage(1);
        navigate("/properties/", {
          state: { searchResult: result, keyWord, checkInDate, checkOutDate },
        });
      } else {
        setError("Your search returned no matches.");
      }
    } catch (error) {
      console.error("Error during search:", error);
      setError(
        "An error occurred while processing your request. Please try again later."
      );
    } finally {
      setKeyWordLoading(false);
    }
  };

  const handleFilter = () => {
    navigate("/properties/");
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setKeyWord(e.target.value);
    setError(""); // Clear error when input changes
  };

  const handleInputClear = () => {
    if (window.location.pathname !== "/")
      navigate(
        window.location.pathname.includes("properties")
          ? "/explore/"
          : "/properties/"
      );
  };

  return (
    <>
      <div className="container-flex">
        <div
          className={` border-shadow bg-white border-round px-3  py-2 px-md-3  `}
        >
          <div
            className={`d-md-flex m-0  gap-md-2 align-items-center ${extraClass}`}
          >
            <div className={` ${sizeClass}`}>
              <div className="row py-3 pl-3 px-md-3 m-0">
                {/* <div className='col-6 col-lg-3 fs-16'> */}
                <div className="col-12 fs-16  px-0">
                  <div className="row align-items-center justify-content-center  ">
                    <div className=" px-0 ">
                      <form
                        onSubmit={(e) => {
                          e.preventDefault();
                          onSearchKeyWord();
                        }}
                      >
                        <div className="input-group input-group-flush input-group-merge border rounded">
                          <div className="input-group-prepend ">
                            <span className="input-group-text  btn px-2 py-2  left-0 ">
                              <i className="fa fa-search"></i>
                            </span>
                          </div>
                          <input
                            className="form-control list-search  border-0 bg-white search-inp px-0 pl-2"
                            type="text"
                            name="searchKey"
                            value={keyWord}
                            onChange={handleInputChange}
                            onInput={(e: any) => {
                              const currentKeyWord = e.target.value;
                              if (
                                prevKeyWord.current &&
                                currentKeyWord === ""
                              ) {
                                handleInputClear();
                              }
                              prevKeyWord.current = currentKeyWord;
                            }}
                            placeholder="Search title, ref, city etc..."
                          />
                          {window.location.pathname !== "/" && keyWord && (
                            <Link
                              className=" btn-link  px-2 text-danger ml-1 d-flex pt-1  text-decoration-none rounded-0 input-group-text  bg-white border-0"
                              to={
                                window.location.pathname.includes("properties")
                                  ? "/explore"
                                  : "/properties"
                              }
                              onClick={() => setKeyWord("")}
                            >
                              <i className="fa fa-x border border-danger fs-8 p-1  rounded-pill"></i>
                            </Link>
                          )}
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <CustomDatePicker
              checkInDate={checkInDate}
              setCheckInDate={setCheckInDate}
              checkOutDate={checkOutDate}
              setCheckOutDate={setCheckOutDate}
            />

            <div className=" d-flex col-md-4 gap-2 p-md-1 mt-2 mt-md-0">
              <button
                className="  btn-outline-search"
                data-bs-toggle="offcanvas"
                data-bs-target="#offcanvasExample"
                aria-controls="offcanvasExample"
                onClick={handleFilter}
              >
                <i className="fas fa-sliders"></i> Filter
              </button>
              <button
                className="btn  btn-search rounded-pill"
                onClick={() => {
                  addPageToSessionStorage(1);
                  onSearchKeyWord();
                }}
              >
                {keyWordLoading ? "Loading" : "Search"}
              </button>
            </div>
          </div>
          {error && <p className="text-danger ">{error}</p>}

          {/* <div
            className="col-4 flex-grow-1 ml-2  d-md-none px-3 gap-2 d-flex align-items-center justify-content-center"
            style={{ height: "24px", width: "24px" }}
          >
            <button
              className=" border-0 btn-outline-search p-0"
              data-bs-toggle="offcanvas"
              data-bs-target="#offcanvasExample"
              aria-controls="offcanvasExample"
              onClick={handleFilter}
            >
              <i className="fas fa-sliders fs-20"></i>
            </button>
            <button
              className="btn bg-primary  text-white rounded-pill d-flex  align-items-center justify-content-center"
              onClick={() => {
                addPageToSessionStorage(1);
                onSearchKeyWord();
              }}
              style={{ height: "28px", width: "28px" }}
            >
              <i className="fas fa-search fs-16"></i>
            </button>
          </div> */}
        </div>
      </div>
    </>
  );
};
