import React, { useMemo, useState } from "react";
import { useSortBy, useTable, useGlobalFilter, Column } from "react-table";
import DevelopersPagination from "@shared/navbars/DevelopersNavbar/Pagination";
import TableEmptyState from "./Empty-State";
import Filters from "./filters.";

type ColumnData = Array<Column<any>>;
type RowData = Array<{ [key: string]: any }>;

interface TableDataProps {
  columnData: ColumnData;
  rowData?: RowData | any;
  loading?: boolean;
  error?: boolean;
  pageSize?: number;
  list?: any;
  label?: string;
  option?: React.ReactNode;
  onClick?: (arg: any) => void;
  actionType?: string;
  more?: (arg: any) => void;
  editCustomer?: (arg: any) => void;
  hasNextPage?: boolean;
  hasPrevPage?: boolean;
  setPageNumber?: (arg: number) => void;
  itemsPerPage?: number;
  setItemsPerPage?: (arg: number) => void;
  totalDocs?: number;
  emptyStateTitle?: string;
  emptyStateSubtitle?: string;
  isFilter?: boolean;
  filterButton?: boolean;
  renderCell?: (cell: any) => any;
}

const DevTable: React.FC<TableDataProps> = ({
  columnData,
  rowData,
  loading,
  onClick = () => {},
  actionType = "",
  emptyStateTitle,
  emptyStateSubtitle,
  isFilter = true,
  filterButton,
  renderCell,
}) => {
  const columns = useMemo(() => columnData, [columnData]);
  const data = useMemo(() => rowData, [rowData]);
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = Math.ceil(10);

  const tableInstance = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    useSortBy
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    tableInstance;

  const handlePageChange = (newPage: number) => {
    setCurrentPage(newPage);
  };

  return (
    <div className="table-container">
      <div className="table-wrapper">
        {isFilter && <Filters filterButton={filterButton} />}
        <div className="table-responsive">
          <table {...getTableProps()} className="table">
            <thead>
              {headerGroups.map((headerGroup: any, idx: number) => (
                <tr
                  key={idx}
                  {...headerGroup.getHeaderGroupProps()}
                  className="table-header"
                >
                  {headerGroup.headers.map((column: any, index: number) => (
                    <th
                      key={index}
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                      className="table-header-cell"
                    >
                      <div className="table-header-content ">
                        <div className="table-header-text fw-bold">
                          {column.render("header")}
                        </div>
                        <span className="table-sort-icon">
                          {column.isSorted ? (
                            column.isSortedDesc ? (
                              <i className="fa-solid fa-angle-up"></i>
                            ) : (
                              <i className="fa-solid fa-angle-down"></i>
                            )
                          ) : (
                            ""
                          )}
                        </span>
                      </div>
                    </th>
                  ))}
                  {actionType && <th className="table-header-cell">Action</th>}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {loading ? (
                <tr>
                  <td colSpan={columns.length + 1} className="table-loading">
                    <p>Loading...</p>
                  </td>
                </tr>
              ) : rows.length < 1 ? (
                <tr className="table-empty">
                  <td colSpan={columns.length + 1} className="table-empty-cell">
                    <TableEmptyState
                      title={emptyStateTitle}
                      subtitle={emptyStateSubtitle}
                    />
                  </td>
                </tr>
              ) : (
                rows.map((row: any) => {
                  prepareRow(row);
                  return (
                    <tr
                      key={row.original.id}
                      {...row.getRowProps()}
                      className="table-row"
                      onClick={(e) => {
                        e.stopPropagation();
                        onClick(row.original);
                      }}
                    >
                      {row.cells.map((cell: any, index: number) => (
                        <td
                          key={index}
                          {...cell.getCellProps()}
                          className="table-cell"
                        >
                          {
                            renderCell
                              ? renderCell(cell) // Use renderCell function if passed
                              : cell.render("Cell") // Default rendering for cells
                          }
                        </td>
                      ))}

                      {actionType === "customer" && (
                        <td className="table-cell">
                          <span className="table-action">
                            {row.original.customerStatus === "active" ? (
                              <p>active</p>
                            ) : (
                              <p>inactive</p>
                            )}
                          </span>
                        </td>
                      )}
                    </tr>
                  );
                })
              )}
            </tbody>
          </table>
          <DevelopersPagination
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={handlePageChange}
          />
        </div>
      </div>
    </div>
  );
};

export default DevTable;
