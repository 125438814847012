import {
  LinkAccount,
  getBankCodes,
  saveAccount,
  updateProperty,
} from "@services/krent.service";
import ktoast from "@services/toast.service";
// import {Modal} from '@shared/controls/Modal';
import { useEffect, useState } from "react";
import Select from "react-select";

export type Bank = {
  value: string;
  label: string;
};

type Prop = {
  id?: string;
  type: "personal" | "landlord";
  propertyId?: string;
  onClose?: any;
};

const AddBankAccount = ({ id, type, propertyId, onClose }: Prop) => {
  const [accountNumber, setAccountNumber] = useState("");
  const [bankCode, setBankCode] = useState("");
  const [bankName, setBankName] = useState("");
  const [accountName, setAccountName] = useState("");
  const [accountType, setAccountType] = useState("");
  const [pin, setPin] = useState("");
  const [sortCode, setSortCode] = useState("");
  const [banks, setBanks] = useState<Bank[]>([]);
  const [filteredBanks, setFilteredBanks] = useState<Bank[]>([]);
  // const [showAddBank, setShowAddBank] = useState(false);
  const [bankLoading, setBankLoading] = useState(false);
  const [accountSuccess, setAccountSuccess] = useState("");
  const [accountError, setAccountError] = useState("");
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [isVerifying, setIsVerifiying] = useState<boolean>(false);

  const handleGetBanks = () => {
    setBankLoading(true);
    getBankCodes()
      .then((resp: any) => {
        const formattedBanks = resp.data.map((bank: any) => ({
          value: bank.code,
          label: bank.name,
        }));
        setBanks(formattedBanks);
        setFilteredBanks(formattedBanks);
        setBankLoading(false);
      })
      .catch((err) => console.log(err));
  };

  // Verify Account
  const handleVerifyAccount = () => {
    let abortController = new AbortController();
    setIsVerifiying(true);
    LinkAccount({ accountNumber, bankCode }, abortController.signal)
      .then((resp: any) => {
        setAccountName(resp.data.account_name);
      })
      .catch((err) => {
        console.log(err);
        setAccountName("Not Found");
        ktoast.error("Failed to Verify Account");
      })
      .finally(() => {
        setIsVerifiying(false);
      });
  };

  // Save Account
  const handleSaveAccount = (e: any) => {
    e.preventDefault();
    let abortController = new AbortController();
    const clearForm = () => {
      setIsSaving(false);
      setAccountName("");
      setAccountNumber("");
      setAccountType("");
      setPin("");
      setSortCode("");
      setBankCode("");
    };
    setIsSaving(true);

    const data = {
      accountName,
      accountNumber,
      bankName,
      accountType,
      ...(type === "personal" && { pin }),
      ...(type === "personal" && sortCode && { sortCode }),
      ...(type === "landlord" && sortCode && { sortCode }),
    };
    if (type === "personal") {
      saveAccount(
        {
          id,
          data,
        },
        abortController.signal
      )
        .then((resp: any) => {
          if (resp.success) {
            setAccountSuccess("Bank Account updated sucessfully");
          }
        })
        .catch((e) => {
          setAccountError(e.errors[0].message);
        })
        .finally(() => {
          clearForm();
        });
    } else if (type === "landlord") {
      updateProperty(propertyId, { landlordAccountDetails: data })
        .then((resp) => {
          if (resp.success) {
            setAccountSuccess("Bank Account updated sucessfully");
          }
        })
        .catch((error: any) => {
          setAccountError(error.errors[0].message);
        })
        .finally(() => {
          clearForm();
        });
    }
  };

  const handleAccountNumberChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setAccountNumber(e.target.value);
  };

  // const handleCloseAccountModal = () => {
  //   setShowAddBank(false);
  //   setAccountName('');
  //   setAccountNumber('');
  //   setAccountType('');
  //   setPin('');
  //   setSortCode('');
  //   setBankCode('');
  //   setAccountSuccess('');
  //   setAccountError('');
  // };

  const handleBankChange = (selectedBank: Bank | null) => {
    if (selectedBank) {
      setBankName(selectedBank.label);
      setBankCode(selectedBank.value);
    } else {
      setBankCode("");
    }
  };

  const handleBankSearch = (searchTerm: string) => {
    const filtered = banks.filter((bank) =>
      bank.label.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredBanks(filtered);
  };

  const handleAccountType = (e: any) => {
    setAccountType(e.target.value);
  };

  useEffect(() => {
    handleGetBanks();
  }, []);
  return (
    <>
      {bankLoading ? (
        <h4 className="container mt-3 pb-5">Loading...</h4>
      ) : (
        <div className="container mt-3 pb-5">
          <div className="flex justify-between items-center mb-4">
            <h4 className="">
              {type === "personal"
                ? "Link Bank Account"
                : "Add Landlord Account"}
            </h4>
            <button onClick={onClose} className="bg-white fw-bold">
              X
            </button>
          </div>
          <form onSubmit={handleSaveAccount}>
            <div className="mb-3">
              <label htmlFor="accountNumber" className="form-label">
                Account Number
              </label>
              <input
                type="text"
                className="form-control"
                id="accountNumber"
                value={accountNumber}
                onChange={handleAccountNumberChange}
                required
              />
            </div>

            <div className="mb-3">
              <label htmlFor="bankSelect" className="form-label">
                Bank
              </label>
              <Select
                id="bankSelect"
                options={filteredBanks}
                value={filteredBanks.find((bank) => bank.value === bankCode)}
                onChange={handleBankChange}
                onInputChange={(inputValue: any) =>
                  handleBankSearch(inputValue)
                }
                isSearchable
              />
            </div>

            <button
              type="button"
              className="btn btn-primary mb-3"
              onClick={handleVerifyAccount}
              disabled={!accountNumber || !bankCode || isVerifying}
            >
              {isVerifying ? (
                <span className="spinner-border spinner-border-sm"></span>
              ) : (
                "Verify Account"
              )}
            </button>

            <div className="mb-3">
              <label htmlFor="accountName" className="form-label">
                Account Name
              </label>
              <input
                type="text"
                className="form-control"
                id="accountName"
                value={accountName}
                readOnly
              />
            </div>
            <div className="mb-3">
              <label htmlFor="accountType" className="form-label">
                Account Type
              </label>

              <select
                className="form-control form-select"
                name="accountType"
                id="accountType"
                value={accountType}
                onChange={handleAccountType}
                required
              >
                <option value="">Select</option>
                <option value="Savings">Savings</option>
                <option value="Current">Current</option>
              </select>
            </div>
            <div className="mb-3">
              <label htmlFor="sortCode" className="form-label">
                Sort Code
              </label>
              <input
                type="text"
                className="form-control"
                id="sortCode"
                value={sortCode}
                onChange={(e: any) => setSortCode(e.target.value)}
              />
              <span className="text-danger fs-10">Optional</span>
            </div>
            {type === "personal" && (
              <div className="mb-3">
                <label htmlFor="pin" className="form-label">
                  Transaction Pin
                </label>
                <input
                  type="password"
                  className="form-control"
                  id="pin"
                  value={pin}
                  onChange={(e: any) => setPin(e.target.value)}
                  required
                />
              </div>
            )}
            {accountSuccess ? (
              <p className="text-success mb-3">{accountSuccess}</p>
            ) : accountError ? (
              <p className="text-danger mb-3">{accountError}</p>
            ) : (
              ""
            )}

            <button
              type="submit"
              className="btn btn-primary"
              // onClick={handleSaveAccount}
              disabled={
                !accountName || (type === "personal" && !pin) || !accountType
              }
            >
              {isSaving ? (
                <span className="spinner-border spinner-border-sm"></span>
              ) : (
                "Save Account"
              )}
            </button>
          </form>
        </div>
      )}
    </>
  );
};
export default AddBankAccount;
