import React, { useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import ShortletCalendarPicker from "./calendar-picker";
import { updateAgentAvailabiltyProp } from "@services/krent.service";
import { toast } from "react-toastify";

interface AgentAvailabilityCalProps {
  availabilityModal: boolean;
  setAvailabilityModal: (value: boolean) => void;
  propertyId: string;
  normalPrice: number;
}

const AgentAvailabilityCal: React.FC<AgentAvailabilityCalProps> = ({
  availabilityModal,
  setAvailabilityModal,
  propertyId,
  normalPrice,
}) => {
  const [status, setStatus] = useState<string>("available");
  const [price, setPrice] = useState<string>("");
  const [currency, setCurrency] = useState("USD");
  // const [overwriteBooked, setOverwriteBooked] = useState<boolean>(false);
  const [selectedDuration, setSelectedDuration] = useState<
    [Date | null, Date | null]
  >([null, null]);
  const handleSave = async () => {
    if (!selectedDuration[0] || !selectedDuration[1]) return;

    const payload = {
      startDate: selectedDuration[0]?.toISOString().split("T")[0],
      endDate: selectedDuration[1]?.toISOString().split("T")[0],
      price: Number(price),
      status,
      // overwriteBooked,
    };

    try {
      const response = await updateAgentAvailabiltyProp(propertyId, payload);
      toast.success((response as any).data);

      setStatus("available");
      setPrice("");
      setCurrency("USD");
      // setOverwriteBooked(false);
      setSelectedDuration([null, null]);

      setAvailabilityModal(false);
    } catch (error) {
      toast.error(` ${(error as any).errors[0].message}`);
    }
  };

  return (
    <Modal
      show={availabilityModal}
      onHide={() => setAvailabilityModal(false)}
      centered
      size="lg"
    >
      <Modal.Header closeButton>
        <div>
          <Modal.Title>Schedule</Modal.Title>
          <p className="text-muted d-block">Set your property availability</p>
        </div>
      </Modal.Header>

      <Modal.Body>
        <ShortletCalendarPicker
          propertyId={propertyId}
          onDurationSelect={setSelectedDuration}
          normalPrice={normalPrice}
        />
        {selectedDuration[0] && selectedDuration[1] && (
          <>
            <Form.Group className="mb-3">
              <Form.Label>Status</Form.Label>
              <Form.Select
                value={status}
                onChange={(e) => setStatus(e.target.value)}
              >
                <option value="available">Available</option>
                <option value="unavailable">Unavailable</option>
              </Form.Select>
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Price</Form.Label>
              <div className="d-flex">
                <Form.Select
                  value={currency}
                  onChange={(e) => setCurrency(e.target.value)}
                  className="me-2 w-auto"
                >
                  <option value="NGN">NGN (₦)</option>
                </Form.Select>

                <Form.Control
                  type="number"
                  placeholder="Enter price"
                  value={price}
                  onChange={(e) => setPrice(e.target.value)}
                />
              </div>
            </Form.Group>

            {/* <Form.Group className="mb-3">
              <Form.Check
                type="checkbox"
                label="Overwrite booked dates"
                checked={overwriteBooked}
                onChange={(e) => setOverwriteBooked(e.target.checked)}
              />
            </Form.Group> */}
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => setAvailabilityModal(false)}>
          Cancel
        </Button>
        <Button
          variant="primary"
          onClick={handleSave}
          disabled={!selectedDuration[0] || !selectedDuration[1]}
        >
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AgentAvailabilityCal;
