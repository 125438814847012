import React, { useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { searchPropByKey } from "@services/krent.service";

type SearchInputProps = {
  placeholder?: string;
};

const SearchInput: React.FC<SearchInputProps> = ({ placeholder }) => {
  const [keyWord, setKeyWord] = useState<string>("");
  const [error, setError] = useState<string>("");
  const prevKeyWord = useRef<string>("");

  const navigate = useNavigate();

  const onSearchKeyWord = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      const result = await searchPropByKey(keyWord);

      if (result.length > 0) {
        navigate("/properties/", { state: { searchResult: result } });
      } else {
        setError("Your search returned no matches.");
        // Optionally clear the error after some time
        // setTimeout(() => {
        //   setError('');
        // }, 4000);
      }
    } catch (error) {
      console.error("Error during search:", error);
      setError(
        "An error occurred while processing your request. Please try again later."
      );
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setKeyWord(e.target.value);
    setError(""); // Clear error when input changes
  };

  const handleInputClear = () => {
    if (window.location.pathname !== "/")
      navigate(
        window.location.pathname.includes("properties")
          ? "/explore/"
          : "/properties/"
      );
  };

  return (
    <div className="row align-items-center justify-content-center search-input bg-white">
      <div className="col col-12">
        {error && <p className="text-danger">{error}</p>}
        <form onSubmit={onSearchKeyWord}>
          <div className="input-group input-group-flush input-group-merge">
            <div className="input-group-prepend">
              <span className="input-group-text btn px-1 py-2 left-0">
                <i className="fa fa-search"></i>
              </span>
            </div>
            <input
              className="form-control list-search border-0"
              type="text"
              name="searchKey"
              value={keyWord}
              onChange={handleInputChange}
              onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
                const currentKeyWord = e.target.value;
                if (prevKeyWord.current && currentKeyWord === "") {
                  handleInputClear();
                }
                prevKeyWord.current = currentKeyWord;
              }}
              placeholder={placeholder || "Search something"}
            />
            {/* <button type='submit' className='btn btn-primary'>
              search
            </button> */}
          </div>
          {window.location.pathname !== "/" && keyWord && (
            <Link
              className="btn-link px-0 text-danger ml-1 d-inline-block mt-1 no-decoration"
              to={
                window.location.pathname.includes("properties")
                  ? "/explore"
                  : "/properties"
              }
              onClick={() => setKeyWord("")}
            >
              Clear
            </Link>
          )}
        </form>
      </div>
    </div>
  );
};

export default SearchInput;
