import React from 'react'
import { formatDateToTime } from '@utils/helpers'
import image from '../../../../assets/images/profile-pic.png'

interface CreatedBy {
  photo?: string
  name?: string
  id?: string
}

interface Forum {
  _id: string
  title: string
  description: string
  createdAt: string
  createdBy: CreatedBy
}

interface ForumSideMenuProps {
  forumData: Forum[]
  onSelectForum: (id: string) => void
  selectedForumId: string | null
}

const ForumSideMenu: React.FC<ForumSideMenuProps> = ({
  forumData,
  onSelectForum,
  selectedForumId,
}) => {
  const handleSelectForum = (id: string) => {
    onSelectForum(id)
  }

  return (
    <div className="h-100 d-flex flex-column">
      <div className="d-flex justify-content-between align-items-center mb-3">
        <h5 className="m-0">Forum</h5>
        <a
          className="btn btn-primary btn-sm"
          href="/developer-forum/add"
        >
          Add a new forum
        </a>
      </div>
      <div className="flex-grow-1 overflow-auto">
        <h6 className="text-muted">FORUMS</h6>
        {forumData.map((forum) => (
          <div
            className={`p-2 cursor-pointer border-bottom ${
              selectedForumId === forum._id ? 'bg-light' : ''
            }`}
            key={forum._id}
            onClick={() => handleSelectForum(forum._id)}
          >
            <div className="text-end">
              <small className="text-muted">
                {formatDateToTime(forum.createdAt)}
              </small>
            </div>
            <div className="d-flex align-items-center">
              <img
                src={image}
                alt="Avatar"
                className="rounded-circle me-3"
                style={{ 
                  width: '55px', 
                  height: '55px', 
                  objectFit: 'cover' 
                }}
              />
              <div>
                <h6 className="mb-1">{forum.title}</h6>
                <p className="text-muted mb-0">{forum.description}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default ForumSideMenu