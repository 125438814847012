import React, { useState } from 'react'
import { DeveloperDashboardLayout } from '@layouts/DeveloperDashboardLayout'
import PropertyDetails from 'components/Developer/Property/property-details'
import PropertyLocation, {
  PropertyLocationState,
} from 'components/Developer/Property/property-location'
import PropertyPricing, {
  PropertyPricingState,
} from 'components/Developer/Property/property-pricing'
import PropertyUtilities from 'components/Developer/Property/property-utilities'
import MultiStepForm from '../MultiStepForm'
import { PropertyDetailsState } from '@interfaces/app.interface'
import {
  createDeveloperSingleProjectProperty,
  uploadProjectImage,
} from '@services/krentDeveloper'
import { useLocation, useNavigate } from 'react-router-dom'
import ProjImgUpload from './proj-img-upload'
import SuccessfulCreation from './success-modal'
interface Amenity {
  title: string
  icon: string
  offers: string[]
}
interface AddPropertyState {
  propertyDetails: PropertyDetailsState
  propertyPricing: PropertyPricingState
  propertyLocation: PropertyLocationState
  amenities: Amenity[]
}

const AddProperty: React.FC = () => {
  const location = useLocation()
  const propertyPrevState = location.state as any
  const projectId = propertyPrevState?.projectId
  const [propertyId, setPropertyId] = useState<string | null>(null)

  const [propertyState, setPropertyState] = useState<AddPropertyState>({
    propertyDetails: propertyPrevState.property || {
      propertyName: '',
      propertyDescription: '',
      numberOfAvailableUnits: '',
      propertyDeedType: '',
      propertyType: '',
      numberOfBedrooms: '',
      numberOfBathrooms: '',
      numberOfFloors: '',
      numberOfParkingSpaces: '',
      numberOfUnits: '',
    },
    propertyPricing: propertyPrevState.property || {
      price: '',
      currency: '',
    },
    amenities: [],

    propertyLocation: propertyPrevState.property ||
      propertyPrevState.property?.location || {
        address: '',
        city: '',
        state: '',
        country: '',
        neighbourhood: '',
        apartmentNumber: '',
        floodRisk: '',
      },
  })
  const [error, setError] = useState<string | null>(null)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [submitError, setSubmitError] = useState<string | null>(null)
  const [successModal, setSuccessModal] = useState(false)

  const [selectedFiles, setSelectedFiles] = useState<File[]>([])
  const [propertySubmitted, setPropertySubmitted] = useState(false)
  const navigate = useNavigate()

  const [isStepValid, setIsStepValid] = useState<boolean>(false)
  // const propertyToEdit = location.state?.property

  const handleFilesSelected = (files: File[]) => {
    setSelectedFiles(files)
  }

  const handlePropertyDetailsChange = (
    newDetails: Partial<PropertyDetailsState>,
  ) => {
    setPropertyState((prevState) => ({
      ...prevState,
      propertyDetails: {
        ...prevState.propertyDetails,
        ...newDetails,
      },
    }))
  }

  const handlePropertyPricingChange = (
    newPricing: Partial<PropertyPricingState>,
  ) => {
    setPropertyState((prevState) => ({
      ...prevState,
      propertyPricing: {
        ...prevState.propertyPricing,
        ...newPricing,
      },
    }))
  }

  const handleAmenitiesChange = (newAmenities: Amenity[]) => {
    setPropertyState((prevState) => ({
      ...prevState,
      amenities: newAmenities,
    }))
  }
  const handlePropertyLocationChange = (
    newLocation: Partial<PropertyLocationState>,
  ) => {
    setPropertyState((prevState) => ({
      ...prevState,
      propertyLocation: {
        ...prevState.propertyLocation,
        ...newLocation,
      },
    }))
  }

  const handlePropertySubmit = async () => {
    setIsSubmitting(true)
    setSubmitError(null)
    try {
      const amenitiesForAPI = {
        entertainment: propertyState.amenities.find(
          (amenity) =>
            amenity.title.toLowerCase().replace(/\s+/g, '') === 'entertainment',
        )?.offers,
        kitchenAndDining: propertyState.amenities.find(
          (amenity) =>
            amenity.title.toLowerCase().replace(/\s+/g, '') ===
            'kitchenanddinning',
        )?.offers,
        otherFeatures: propertyState.amenities.find(
          (amenity) =>
            amenity.title.toLowerCase().replace(/\s+/g, '') === 'otherfeatures',
        )?.offers,
        safety: propertyState.amenities.find(
          (amenity) =>
            amenity.title.toLowerCase().replace(/\s+/g, '') === 'safety',
        )?.offers,
        utilitiesAndAmenities: propertyState.amenities.find(
          (amenity) =>
            amenity.title.toLowerCase().replace(/\s+/g, '') ===
            'utilitiesandamenities',
        )?.offers,
      }

      const response = await createDeveloperSingleProjectProperty({
        projectId: projectId,
        propertyName: propertyState.propertyDetails.propertyName,
        propertyType: propertyState.propertyDetails.propertyType,
        numberOfAvailableUnits:
          propertyState.propertyDetails.numberOfAvailableUnits,
        propertyDeedType: propertyState.propertyDetails.propertyDeedType,
        propertyDescription: propertyState.propertyDetails.propertyDescription,
        address: propertyState.propertyLocation.address,
        city: propertyState.propertyLocation.city,
        state: propertyState.propertyLocation.state,
        country: propertyState.propertyLocation.country,
        neighbourhood: propertyState.propertyLocation.neighbourhood,
        apartmentNumber: propertyState.propertyLocation.apartmentNumber,
        ...amenitiesForAPI,
        price: propertyState.propertyPricing.price,
        currency: propertyState.propertyPricing.currency,
        numberOfFloors: propertyState.propertyDetails.numberOfFloors,
        numberOfParkingSpaces:
          propertyState.propertyDetails.numberOfParkingSpaces,
        numberOfBedrooms: propertyState.propertyDetails.numberOfBedrooms,
        numberOfBathrooms: propertyState.propertyDetails.numberOfBathrooms,
        numberOfUnits: propertyState.propertyDetails.numberOfUnits,
        floodRisk: propertyState.propertyLocation.floodRisk,
      })
      setPropertyId(response.data._id)
      setPropertySubmitted(true)
      return !response.error
    } catch (error) {
      setSubmitError('Failed to create property. Please try again.')
      return false
    } finally {
      setIsSubmitting(false)
    }
  }
  const handleStepSubmit = async (currentStep: number): Promise<boolean> => {
    if (currentStep === 4) {
      const success = await handlePropertySubmit()
      if (!success) {
        return false
      }
    } else if (currentStep === 5 && !propertySubmitted) {
      setSubmitError('Please submit the property details first.')
      return false
    }
    return true
  }
  const handleImageSubmit = async () => {
    setIsSubmitting(true)
    setError(null)
    try {
      const formData = new FormData()
      selectedFiles.forEach((file) => {
        formData.append(`otherImages`, file)
      })

      await uploadProjectImage('property', propertyId as string, formData)

      setSuccessModal(true)
      setTimeout(() => {
        navigate('/developer-projects')
      }, 2000)
    } catch (err) {
      setError('Failed to submit property images. Please try again.')
    } finally {
      setIsSubmitting(false)
    }
  }

  return (
    <DeveloperDashboardLayout title="Add a new listing">
      <div>
        <div className="flex items-center gap-2">
          <p>Home</p>
          <i className="fa-light fa-angle-right"></i>
          <p className="">Dashboard</p>
          <i className="fa-light fa-angle-right"></i>
          <p>Listing</p>
          <i className="fa-light fa-angle-right"></i>
          <p className="text-primary">Add a new listing</p>
        </div>
        <div>
          <h6 className="my-3">
            Fill the details to add properties to your listings
          </h6>
        </div>
        <MultiStepForm
          steps={[
            <PropertyDetails
              propertyDetails={propertyState.propertyDetails}
              onValidationChange={(isValid) => setIsStepValid(isValid)}
              onPropertyDetailsChange={handlePropertyDetailsChange}
            />,
            <PropertyPricing
              pricingDetails={propertyState.propertyPricing}
              onPricingDetailsChange={handlePropertyPricingChange}
              onValidationChange={(isValid) => setIsStepValid(isValid)}
            />,
            <PropertyUtilities onAmenitiesChange={handleAmenitiesChange} />,
            <PropertyLocation
              locationDetails={propertyState.propertyLocation}
              onLocationDetailsChange={handlePropertyLocationChange}
            />,
            <div>
              <label className="fw-bolder form-label text-capitalize">
                Upload Property Images
              </label>
              <ProjImgUpload
                onFilesSelected={handleFilesSelected}
                uploading={isSubmitting}
                multiple={true}
              />
            </div>,
          ]}
          handleSubmit={handleImageSubmit}
          onStepSubmit={handleStepSubmit}
          isCurrentStepValid={isStepValid}
        />
        {error && <div className="text-red-500 mt-4">{error}</div>}
        {submitError && <div className="text-red-500 mt-4">{submitError}</div>}
      </div>
      <SuccessfulCreation
        setSuccessModal={setSuccessModal}
        successModal={successModal}
        type={'Property'}
      />
    </DeveloperDashboardLayout>
  )
}

export default AddProperty
