import { navItems } from "@utils/constants";
import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { Navbar, Container, Offcanvas } from "react-bootstrap";
// import krentLogo from '../../assets/images/krent-xmas.svg'

const StaycationHeader = () => {
  const location = useLocation();
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <header className="relative z-10 w-full px-6 py-4">
      <Container className="mx-auto">
        <Navbar expand={false} className="p-0">
          <Container fluid className="p-0">
            <Navbar.Brand>
              <Link to="/staycation">
                {/* <img src={krentLogo} alt="logo" /> */}
              </Link>
            </Navbar.Brand>

            <Navbar.Toggle
              aria-controls="offcanvasNavbar"
              onClick={handleShow}
              className="d-md-none border-0 text-white"
            >
              <i className="fa-regular fa-bars text-white"></i>
            </Navbar.Toggle>

            <nav className="d-none d-md-flex items-center space-x-8 gap-40">
              {navItems.map((nav, idx) => (
                <a
                  key={idx}
                  href={nav.url}
                  className={`fs-16 text-white ${
                    location.pathname === nav.url ? "fw-bold" : ""
                  }`}
                >
                  {nav.title}
                </a>
              ))}
            </nav>

            <div className="d-none d-md-block">
              <a
                href="/staycation-packages"
                className="bg-primary rounded-10 text-white p-3 fw-bold px-5"
              >
                Book Now &nbsp;
                <i className="fa-regular fa-arrow-up-right"></i>
              </a>
            </div>

            <Navbar.Offcanvas
              show={show}
              onHide={handleClose}
              id="offcanvasNavbar"
              aria-labelledby="offcanvasNavbarLabel"
              placement="end"
            >
              <Offcanvas.Header closeButton>
                <Offcanvas.Title id="offcanvasNavbarLabel"></Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body>
                <div className="d-flex flex-column gap-4">
                  {navItems.map((nav, idx) => (
                    <a
                      key={idx}
                      href={nav.url}
                      className={`fs-16 text-dark text-decoration-none ${
                        location.pathname === nav.url ? "fw-bold" : ""
                      }`}
                      onClick={handleClose}
                    >
                      {nav.title}
                    </a>
                  ))}
                  <div className="mt-4">
                    <a
                      href="/staycation-packages"
                      className="bg-primary rounded-10 text-white p-3 fw-bold px-5"
                    >
                      Book Now &nbsp;
                      <i className="fa-regular fa-arrow-up-right"></i>
                    </a>
                  </div>
                </div>
              </Offcanvas.Body>
            </Navbar.Offcanvas>
          </Container>
        </Navbar>
      </Container>
    </header>
  );
};

export default StaycationHeader;
