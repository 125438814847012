import { BaseApiResponse, PaginatedResponse } from "@interfaces/app.interface";
import { NotificationsInterfaceResponse } from "@interfaces/notifications.interface";
import {
  PropertyKind,
  PropertyListResponse,
  PropertyLocationResponse,
  PropertySpace,
  PropertySummaryResponse,
} from "@interfaces/properties.interface";
import {
  ChangePasswordRequest,
  ChangePinRequest,
  CreatePinRequest,
} from "@interfaces/user.interface";
import { fetchCookie } from "@utils/cookies";
import axios, { AxiosResponse } from "axios";
import customFetch from "./axio";
import { toast } from "react-toastify";
import { TransactionResponse } from "@interfaces/transaction";
import { WalletResponse } from "@interfaces/wallet.interface";

const url = process.env["REACT_APP_API_BASE_URL"];

export const getProperties = (
  options: { [x: string]: string },
  signal: AbortSignal
): Promise<
  BaseApiResponse<
    PropertyListResponse[],
    PaginatedResponse,
    PropertySummaryResponse
  >
> => {
  let query = Object.keys(options)
    .map((key) => key + "=" + options[key])
    .join("&");
  return new Promise((resolve, reject) => {
    return fetch(`${process.env["REACT_APP_API_BASE_URL"]}/listing/?${query}`, {
      signal: signal,
      headers: {
        "Content-Type": "application/json",
      },
      method: "GET",
    })
      .then((res) => {
        return res.json();
      })
      .then(
        (
          response: BaseApiResponse<
            PropertyListResponse[],
            PaginatedResponse,
            PropertySummaryResponse
          >
        ) => {
          if (response.success) {
            resolve(response);
          } else {
            reject(response);
          }
        }
      )
      .catch((err) => {
        reject(err);
      });
  });
};

export const getAllProperties = (
  signal: AbortSignal
): Promise<
  BaseApiResponse<
    PropertyListResponse[],
    PaginatedResponse,
    PropertySummaryResponse
  >
> => {
  return new Promise((resolve, reject) => {
    return fetch(`${process.env["REACT_APP_API_BASE_URL"]}/listing`, {
      signal: signal,
      headers: {
        "Content-Type": "application/json",
      },
      method: "GET",
    })
      .then((res) => {
        return res.json();
      })
      .then(
        (
          response: BaseApiResponse<
            PropertyListResponse[],
            PaginatedResponse,
            PropertySummaryResponse
          >
        ) => {
          if (response.success) {
            resolve(response);
          } else {
            reject(response);
          }
        }
      )
      .catch((err) => {
        reject(err);
      });
  });
};

export const getTopLocations = (
  signal: AbortSignal
): Promise<BaseApiResponse<PropertyLocationResponse[]>> => {
  return new Promise((resolve, reject) => {
    return fetch(
      `${process.env["REACT_APP_API_BASE_URL"]}/properties/top-locations`,
      {
        signal: signal,
        headers: {
          "Content-Type": "application/json",
        },
        method: "GET",
      }
    )
      .then((res) => {
        return res.json();
      })
      .then((data: BaseApiResponse<PropertyLocationResponse[]>) => {
        if (data.success) {
          resolve(data);
        } else {
          reject(data);
        }
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getUserNotifications = (
  user: string,
  signal: AbortSignal
): Promise<BaseApiResponse<NotificationsInterfaceResponse[]>> => {
  let _token: string | null = fetchCookie("1Q_SPA");
  return new Promise((resolve, reject) => {
    return fetch(
      `${process.env["REACT_APP_API_BASE_URL"]}/notifications/user/${user}`,
      {
        signal: signal,
        headers: {
          Authorization: `Bearer ${_token}`,
          "Content-Type": "application/json",
        },
        method: "GET",
      }
    )
      .then((res) => {
        return res.json();
      })
      .then((data: BaseApiResponse<NotificationsInterfaceResponse[]>) => {
        if (data.success) {
          resolve(data);
        } else {
          reject(data);
        }
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export const getUnreadUserNotifications = (
  user: string,
  signal: AbortSignal
): Promise<BaseApiResponse<NotificationsInterfaceResponse[]>> => {
  let _token: string | null = fetchCookie("1Q_SPA");
  return new Promise((resolve, reject) => {
    return fetch(
      `${process.env["REACT_APP_API_BASE_URL"]}/notifications/user/${user}/unread-count`,
      {
        signal: signal,
        headers: {
          Authorization: `Bearer ${_token}`,
          "Content-Type": "application/json",
        },
        method: "GET",
      }
    )
      .then((res) => {
        return res.json();
      })
      .then((data: BaseApiResponse<NotificationsInterfaceResponse[]>) => {
        if (data.success) {
          resolve(data);
        } else {
          reject(data);
        }
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export const markReadNotification = (
  id: string,
  signal: AbortSignal
): Promise<BaseApiResponse<NotificationsInterfaceResponse[]>> => {
  let _token: string | null = fetchCookie("1Q_SPA");
  return new Promise((resolve, reject) => {
    return fetch(
      `${process.env["REACT_APP_API_BASE_URL"]}/notifications/${id}`,
      {
        signal: signal,
        headers: {
          Authorization: `Bearer ${_token}`,
          "Content-Type": "application/json",
        },
        method: "GET",
      }
    )
      .then((res) => {
        return res.json();
      })
      .then((data: BaseApiResponse<NotificationsInterfaceResponse[]>) => {
        if (data.success) {
          resolve(data);
        } else {
          reject(data);
        }
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getPropertyKinds = (
  signal: AbortSignal
): Promise<BaseApiResponse<PropertyKind[], PaginatedResponse>> => {
  return new Promise((resolve, reject) => {
    return fetch(`${process.env["REACT_APP_API_BASE_URL"]}/kinds`, {
      signal: signal,
      headers: {
        "Content-Type": "application/json",
      },
      method: "GET",
    })
      .then((res) => {
        return res.json();
      })
      .then((data: BaseApiResponse<PropertyKind[], PaginatedResponse>) => {
        if (data.success) {
          resolve(data);
        } else {
          reject(data);
        }
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getPropertyAmenities = (
  signal: AbortSignal
): Promise<BaseApiResponse<PropertyKind[], PaginatedResponse>> => {
  let _token: string | null = fetchCookie("1Q_SPA");
  return new Promise((resolve, reject) => {
    return fetch(`${process.env["REACT_APP_API_BASE_URL"]}/amenities`, {
      signal: signal,
      headers: {
        Authorization: `Bearer ${_token}`,
        "Content-Type": "application/json",
      },
      method: "GET",
    })
      .then((res) => {
        return res.json();
      })
      .then((data: BaseApiResponse<PropertyKind[], PaginatedResponse>) => {
        if (data.success) {
          resolve(data);
        } else {
          reject(data);
        }
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getPropertySpaces = (
  signal: AbortSignal
): Promise<BaseApiResponse<PropertySpace[], PaginatedResponse>> => {
  return new Promise((resolve, reject) => {
    return fetch(`${process.env["REACT_APP_API_BASE_URL"]}/spaces`, {
      signal: signal,
      headers: {
        "Content-Type": "application/json",
      },
      method: "GET",
    })
      .then((res) => {
        return res.json();
      })
      .then((data: BaseApiResponse<PropertySpace[], PaginatedResponse>) => {
        if (data.success) {
          resolve(data);
        } else {
          reject(data);
        }
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getProfile = (signal: AbortSignal) => {
  let _token: string | null = fetchCookie("1Q_SPA");
  return new Promise((resolve, reject) => {
    return fetch(`${process.env["REACT_APP_API_BASE_URL"]}/auth/me/`, {
      signal: signal,
      headers: {
        Authorization: `Bearer ${_token}`,
        "Content-Type": "application/json",
      },
      method: "GET",
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        if (data.errors) {
          reject(data);
        } else {
          resolve(data);
        }
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const updateProfile = (
  id: string,
  payload: any,
  signal: AbortSignal
) => {
  let _token: string | null = fetchCookie("1Q_SPA");
  return new Promise((resolve, reject) => {
    return fetch(`${process.env["REACT_APP_API_BASE_URL"]}/users/${id}`, {
      signal: signal,
      headers: {
        Authorization: `Bearer ${_token}`,
        "Content-Type": "application/json",
      },
      method: "PUT",
      body: JSON.stringify(payload),
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        if (data.errors) {
          reject(data);
        } else {
          resolve(data);
        }
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const updateProfilePic = (
  id: string,
  payload: any,
  signal: AbortSignal
) => {
  let _token: string | null = fetchCookie("1Q_SPA");
  return new Promise((resolve, reject) => {
    return fetch(
      `${process.env["REACT_APP_API_BASE_URL"]}/users/${id}/profile-photo`,
      {
        signal: signal,
        headers: {
          Authorization: `Bearer ${_token}`,
        },
        method: "PUT",
        body: payload,
      }
    )
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        if (data.errors) {
          reject(data);
        } else {
          resolve(data);
        }
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export const addNationalId = (
  id: string,
  payload: any,
  signal: AbortSignal
) => {
  let _token: string | null = fetchCookie("1Q_SPA");
  return new Promise((resolve, reject) => {
    return fetch(
      `${process.env["REACT_APP_API_BASE_URL"]}/users/government-id/${id}`,
      {
        signal: signal,
        headers: {
          Authorization: `Bearer ${_token}`,
        },
        method: "PUT",
        body: payload,
      }
    )
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        if (data.errors) {
          reject(data);
        } else {
          resolve(data);
        }
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getWallet = (
  id: string,
  signal: AbortSignal
): Promise<WalletResponse> => {
  let _token: string | null = fetchCookie("1Q_SPA");
  return new Promise((resolve, reject) => {
    return fetch(`${process.env["REACT_APP_API_BASE_URL"]}/wallets/${id}`, {
      signal: signal,
      headers: {
        Authorization: `Bearer ${_token}`,
        "Content-Type": "application/json",
      },
      method: "GET",
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getWalletTransactions = (id: string, signal: AbortSignal) => {
  let _token: string | null = fetchCookie("1Q_SPA");
  return new Promise((resolve, reject) => {
    return fetch(
      `${process.env["REACT_APP_API_BASE_URL"]}/wallet-transactions/wallet/${id}`,
      {
        signal: signal,
        headers: {
          Authorization: `Bearer ${_token}`,
          "Content-Type": "application/json",
        },
        method: "GET",
      }
    )
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getUnpaidCautionFees = (signal: AbortSignal) => {
  let _token: string | null = fetchCookie("1Q_SPA");
  return new Promise((resolve, reject) => {
    return fetch(
      `${process.env["REACT_APP_API_BASE_URL"]}/transactions/caution-fees`,
      {
        signal: signal,
        headers: {
          Authorization: `Bearer ${_token}`,
          "Content-Type": "application/json",
        },
        method: "GET",
      }
    )
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

// Checkout
export const checkout = (id: string, signal: AbortSignal) => {
  const _token: string | null = fetchCookie("1Q_SPA");

  return new Promise((resolve, reject) => {
    return fetch(
      `${process.env["REACT_APP_API_BASE_URL"]}/transactions/${id}/checkout`,
      {
        signal: signal,
        headers: {
          Authorization: `Bearer ${_token}`,
          "Content-Type": "application/json",
        },
        method: "GET",
      }
    )
      .then((res) => {
        return res.json().then((data) => {
          if (!res.ok) {
            throw data;
          }
          return data;
        });
      })
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

// get bank and bank codes
export const getBankCodes = () => {
  // let _token: string | null = fetchCookie('1Q_SPA');
  return new Promise((resolve, reject) => {
    return fetch("https://api.paystack.co/bank", {
      // signal: signal,
      headers: {
        // Authorization: `Bearer ${_token}`,
        "Content-Type": "application/json",
      },
      method: "GET",
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

// Link Account
export const LinkAccount = (payload: any, signal: AbortSignal) => {
  let _token: string | null = fetchCookie("1Q_SPA");
  return new Promise((resolve, reject) => {
    fetch(
      `${process.env["REACT_APP_API_BASE_URL"]}/wallets/resolve-bank-account`,
      {
        signal: signal,
        headers: {
          Authorization: `Bearer ${_token}`,
          "Content-Type": "application/json",
        },
        method: "post",
        body: JSON.stringify(payload),
      }
    )
      .then((response: any) => {
        return response.json();
      })
      .then((data) => {
        if (data.success) {
          resolve(data);
        } else {
          reject(data);
        }
      })
      .catch((err) => {
        reject(err);

        console.error("Error verifying account:", err);
      });
  });
};

// Save Account
export const saveAccount = ({ id, data }: any, signal: AbortSignal) => {
  let _token: string | null = fetchCookie("1Q_SPA");
  return new Promise((resolve, reject) => {
    fetch(`${process.env["REACT_APP_API_BASE_URL"]}/wallets/${id}`, {
      signal: signal,
      headers: {
        Authorization: `Bearer ${_token}`,
        "Content-Type": "application/json",
      },
      method: "put",
      body: JSON.stringify(data),
    })
      .then((response: any) => {
        return response.json();
      })
      .then((res) => {
        if (res.success) {
          resolve(res);
        } else {
          reject(res);
        }
      })
      .catch((err) => {
        reject(err);

        console.error("Error verifying account:", err);
      });
  });
};

// Withdraw from wallet
export const withdrawFunds = ({ id, data }: any, signal: AbortSignal) => {
  let _token: string | null = fetchCookie("1Q_SPA");
  return new Promise((resolve, reject) => {
    fetch(`${process.env["REACT_APP_API_BASE_URL"]}/wallets/${id}`, {
      signal: signal,
      headers: {
        Authorization: `Bearer ${_token}`,
        "Content-Type": "application/json",
      },
      method: "post",
      body: JSON.stringify(data),
    })
      .then((response: any) => {
        return response.json();
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);

        console.error("Error withdrawing funds:", err);
      });
  });
};
// Request Deposit
export const requestDeposit = ({ id, data }: any, signal: AbortSignal) => {
  let _token: string | null = fetchCookie("1Q_SPA");
  return new Promise((resolve, reject) => {
    fetch(`${process.env["REACT_APP_API_BASE_URL"]}/wallets/${id}/deposit`, {
      signal: signal,
      headers: {
        Authorization: `Bearer ${_token}`,
        "Content-Type": "application/json",
      },
      method: "post",
      body: JSON.stringify(data),
    })
      .then((response: any) => {
        return response.json();
      })
      .then((res) => {
        if (res.success) {
          resolve(res);
        } else {
          reject(res);
        }
      })
      .catch((err) => {
        reject(err);

        console.error("Error withdrawing funds:", err);
      });
  });
};

export const getPropertyTransactions = (id: string, signal: AbortSignal) => {
  let _token: string | null = fetchCookie("1Q_SPA");
  return new Promise((resolve, reject) => {
    return fetch(
      `${process.env["REACT_APP_API_BASE_URL"]}/users/${id}/transactions`,
      {
        signal: signal,
        headers: {
          Authorization: `Bearer ${_token}`,
          "Content-Type": "application/json",
        },
        method: "GET",
      }
    )
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getSinglePropertyTransaction = (
  id: string,
  signal: AbortSignal
) => {
  let _token: string | null = fetchCookie("1Q_SPA");

  return new Promise((resolve, reject) => {
    return fetch(
      `${process.env["REACT_APP_API_BASE_URL"]}/transactions/${id}`,
      {
        signal: signal,
        headers: {
          Authorization: `Bearer ${_token}`,
          "Content-Type": "application/json",
        },
        method: "GET",
      }
    )
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        if (data.errors) {
          reject(data);
        } else {
          resolve(data);
        }
      })
      .catch((err) => {
        reject(err);
      });
  });
};

// fetch all extended transactions
export const getExtendedTransactions = (
  id: string,
  userId: string,
  signal: AbortSignal
) => {
  let _token: string | null = fetchCookie("1Q_SPA");

  return new Promise((resolve, reject) => {
    return fetch(
      `${process.env["REACT_APP_API_BASE_URL"]}/users/${userId}/transactions?originalTransaction=${id}`,
      {
        signal: signal,
        headers: {
          Authorization: `Bearer ${_token}`,
          "Content-Type": "application/json",
        },
        method: "GET",
      }
    )
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        if (data.errors) {
          reject(data);
        } else {
          resolve(data);
        }
      })
      .catch((err) => {
        reject(err);
      });
  });
};

// Accept Transactions
export const acceptTransaction = (id: string, couponCode?: string) => {
  let _token: string | null = fetchCookie("1Q_SPA");
  const url = couponCode
    ? `${process.env["REACT_APP_API_BASE_URL"]}/transactions/${id}/accept?coupon=${couponCode}`
    : `${process.env["REACT_APP_API_BASE_URL"]}/transactions/${id}/accept`;

  return new Promise((resolve, reject) => {
    return fetch(url, {
      headers: {
        Authorization: `Bearer ${_token}`,
        "Content-Type": "application/json",
      },
      method: "GET",
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        if (data.errors) {
          reject(data);
        } else {
          resolve(data);
        }
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const inspectionStatus = (id: string) => {
  let _token: string | null = fetchCookie("1Q_SPA");
  return new Promise((resolve, reject) => {
    return fetch(
      `${process.env["REACT_APP_API_BASE_URL"]}/transactions/${id}/inspection-status`,
      {
        headers: {
          Authorization: `Bearer ${_token}`,
          "Content-Type": "application/json",
        },
        method: "PUT",
        body: JSON.stringify({
          status: "skipped",
        }),
      }
    )
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        if (data.errors) {
          reject(data);
        } else {
          resolve(data);
        }
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export const updateInspectionStatusFalse = (id: string) => {
  let _token: string | null = fetchCookie("1Q_SPA");
  return new Promise((resolve, reject) => {
    return fetch(
      `${process.env["REACT_APP_API_BASE_URL"]}/transactions/${id}/inspection-done`,
      {
        headers: {
          Authorization: `Bearer ${_token}`,
          "Content-Type": "application/json",
        },
        method: "PUT",
        body: JSON.stringify({ inspected: false }),
      }
    )
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        if (data.errors) {
          reject(data);
        } else {
          resolve(data);
        }
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export const cancelTransaction = (id: string, reason: string) => {
  let _token: string | null = fetchCookie("1Q_SPA");
  return new Promise((resolve, reject) => {
    return fetch(
      `${process.env["REACT_APP_API_BASE_URL"]}/transactions/${id}/cancel`,
      {
        headers: {
          Authorization: `Bearer ${_token}`,
          "Content-Type": "application/json",
        },
        method: "PUT",
        body: JSON.stringify({ reason }),
      }
    )
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        if (data.errors) {
          reject(data);
        } else {
          resolve(data);
        }
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export const updateCancellation = (
  id: string,
  status: "rejected" | "completed"
) => {
  let _token: string | null = fetchCookie("1Q_SPA");

  const body =
    status === "rejected" ? { status: "rejected" } : { status: "completed" };

  return new Promise((resolve, reject) => {
    return fetch(
      `${process.env["REACT_APP_API_BASE_URL"]}/cancellations/${id}`,
      {
        headers: {
          Authorization: `Bearer ${_token}`,
          "Content-Type": "application/json",
        },
        method: "PUT",
        body: JSON.stringify(body),
      }
    )
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        if (data.errors) {
          reject(data);
        } else {
          resolve(data);
        }
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export const reportIncident = (id: string, description: string) => {
  let _token: string | null = fetchCookie("1Q_SPA");
  return new Promise((resolve, reject) => {
    return fetch(
      `${process.env["REACT_APP_API_BASE_URL"]}/transactions/${id}/incidents`,
      {
        headers: {
          Authorization: `Bearer ${_token}`,
          "Content-Type": "application/json",
        },
        method: "PUT",
        body: JSON.stringify({ description }),
      }
    )
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        if (data.errors) {
          reject(data);
        } else {
          resolve(data);
        }
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const updatePropertyAvailability = (
  id: string,
  payload: "available" | "unavailable"
) => {
  let _token: string | null = fetchCookie("1Q_SPA");
  return new Promise((resolve, reject) => {
    return fetch(
      `${process.env["REACT_APP_API_BASE_URL"]}/transactions/${id}/update-availability`,
      {
        headers: {
          Authorization: `Bearer ${_token}`,
          "Content-Type": "application/json",
        },
        method: "PUT",
        body: JSON.stringify({
          property_avalability_status: payload,
        }),
      }
    )
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        if (data.errors) {
          reject(data);
        } else {
          resolve(data);
        }
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const reconfirmPropertyAvailability = (
  id: string,
  payload: "available" | "unavailable"
) => {
  let _token: string | null = fetchCookie("1Q_SPA");
  return new Promise((resolve, reject) => {
    return fetch(
      `${process.env["REACT_APP_API_BASE_URL"]}/transactions/${id}/reconfirm-availability`,
      {
        headers: {
          Authorization: `Bearer ${_token}`,
          "Content-Type": "application/json",
        },
        method: "PUT",
        body: JSON.stringify({
          property_avalability_status: payload,
        }),
      }
    )
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        if (data.errors) {
          reject(data);
        } else {
          resolve(data);
        }
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export const updateRescheduleAvailability = (
  id: string,
  payload: "available" | "unavailable"
) => {
  let _token: string | null = fetchCookie("1Q_SPA");
  return new Promise((resolve, reject) => {
    return fetch(
      `${process.env["REACT_APP_API_BASE_URL"]}/transactions/${id}/reschedule/update-availability`,
      {
        headers: {
          Authorization: `Bearer ${_token}`,
          "Content-Type": "application/json",
        },
        method: "PUT",
        body: JSON.stringify({
          property_avalability_status: payload,
        }),
      }
    )
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        if (data.errors) {
          reject(data);
        } else {
          resolve(data);
        }
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const pickInspectionForTransaction = (id: string, payload: any) => {
  let _token: string | null = fetchCookie("1Q_SPA");
  return new Promise((resolve, reject) => {
    return fetch(
      `${process.env["REACT_APP_API_BASE_URL"]}/transactions/${id}/request-inspection`,
      {
        headers: {
          Authorization: `Bearer ${_token}`,
          "Content-Type": "application/json",
        },
        method: "PUT",
        body: JSON.stringify(payload),
      }
    )
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        if (data.errors) {
          reject(data);
        } else {
          resolve(data);
        }
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const updateInspectionStatus = (id: string) => {
  let _token: string | null = fetchCookie("1Q_SPA");
  return new Promise((resolve, reject) => {
    return fetch(
      `${process.env["REACT_APP_API_BASE_URL"]}/transactions/${id}/inspection-done`,
      {
        headers: {
          Authorization: `Bearer ${_token}`,
          "Content-Type": "application/json",
        },
        method: "PUT",
        body: JSON.stringify({ inspected: true }),
      }
    )
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        if (data.errors) {
          reject(data);
        } else {
          resolve(data);
        }
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const confirmInspectionForTransaction = (id: string, payload: any) => {
  let _token: string | null = fetchCookie("1Q_SPA");
  return new Promise((resolve, reject) => {
    return fetch(
      `${process.env["REACT_APP_API_BASE_URL"]}/transactions/${id}/fix-inspection-date`,
      {
        headers: {
          Authorization: `Bearer ${_token}`,
          "Content-Type": "application/json",
        },
        method: "PUT",
        body: JSON.stringify({
          inspection_date: payload,
        }),
      }
    )
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        if (data.errors) {
          reject(data);
        } else {
          resolve(data);
        }
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getSingleProperty = (slug: string, signal: AbortSignal) => {
  let _token: string | null = fetchCookie("1Q_SPA");
  return new Promise((resolve, reject) => {
    return fetch(
      `${process.env["REACT_APP_API_BASE_URL"]}/properties/slug/${slug}`,
      {
        signal: signal,
        headers: {
          Authorization: `Bearer ${_token}`,
          "Content-Type": "application/json",
        },
        method: "GET",
      }
    )
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getSingleListingProperty = (id: string, signal: AbortSignal) => {
  let _token: string | null = fetchCookie("1Q_SPA");
  return new Promise((resolve, reject) => {
    return fetch(`${process.env["REACT_APP_API_BASE_URL"]}/properties/${id}`, {
      signal: signal,
      headers: {
        Authorization: `Bearer ${_token}`,
        "Content-Type": "application/json",
      },
      method: "GET",
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const startPropertyTransactions = (payload: any) => {
  let _token: string | null = fetchCookie("1Q_SPA");
  return new Promise((resolve, reject) => {
    return fetch(`${process.env["REACT_APP_API_BASE_URL"]}/transactions`, {
      // signal: signal,
      headers: {
        Authorization: `Bearer ${_token}`,
        "Content-Type": "application/json",
      },
      method: "POST",
      body: JSON.stringify(payload),
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        if (data.errors) {
          reject(data);
        } else {
          resolve(data);
        }
      })
      .catch((err) => {
        reject(err);
      });
  });
};

// Renew Booking
export const renewBooking = (payload: any) => {
  let _token: string | null = fetchCookie("1Q_SPA");
  return new Promise((resolve, reject) => {
    return fetch(
      `${process.env["REACT_APP_API_BASE_URL"]}/transactions/extend`,
      {
        // signal: signal,
        headers: {
          Authorization: `Bearer ${_token}`,
          "Content-Type": "application/json",
        },
        method: "POST",
        body: JSON.stringify(payload),
      }
    )
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        if (data.errors) {
          reject(data);
        } else {
          resolve(data);
        }
      })
      .catch((err) => {
        reject(err);
      });
  });
};
// Renew Booking
export const rescheduleBooking = (payload: any) => {
  let _token: string | null = fetchCookie("1Q_SPA");
  return new Promise((resolve, reject) => {
    return fetch(
      `${process.env["REACT_APP_API_BASE_URL"]}/transactions/${payload.id}/reschedule`,
      {
        // signal: signal,
        headers: {
          Authorization: `Bearer ${_token}`,
          "Content-Type": "application/json",
        },
        method: "PUT",
        body: JSON.stringify(payload.newDate),
      }
    )
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        if (data.errors) {
          reject(data);
        } else {
          resolve(data);
        }
      })
      .catch((err) => {
        reject(err);
      });
  });
};

// Payment
export const makePayment = (id: string, payload: any) => {
  let _token: string | null = fetchCookie("1Q_SPA");
  return new Promise((resolve, reject) => {
    return fetch(
      `${process.env["REACT_APP_API_BASE_URL"]}/transactions/${id}/pay-paystack`,
      {
        // signal: signal,
        headers: {
          Authorization: `Bearer ${_token}`,
          "Content-Type": "application/json",
        },
        method: "POST",
        body: JSON.stringify(payload),
      }
    )
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        if (data.errors) {
          reject(data);
        } else {
          resolve(data);
        }
      })
      .catch((err) => {
        reject(err);
      });
  });
};

//Verify Payment
export const verifyPayment = (id: string, reference: string) => {
  let _token: string | null = fetchCookie("1Q_SPA");
  return new Promise((resolve, reject) => {
    return fetch(
      `${process.env["REACT_APP_API_BASE_URL"]}/transactions/${id}/verify-paystack/${reference}`,
      {
        // signal: signal,
        headers: {
          Authorization: `Bearer ${_token}`,
          "Content-Type": "application/json",
        },
        method: "GET",
      }
    )
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        if (data.errors) {
          reject(data);
        } else {
          resolve(data);
        }
      })
      .catch((err) => {
        reject(err);
      });
  });
};

// Upload Legal Document
export const uploadLegalDoc = (id: string, payload: any) => {
  let _token: string | null = fetchCookie("1Q_SPA");
  return new Promise((resolve, reject) => {
    return fetch(
      `${process.env["REACT_APP_API_BASE_URL"]}/legal/${id}/uploadLegalDocument`,
      {
        // signal: signal,
        headers: {
          Authorization: `Bearer ${_token}`,
        },
        method: "POST",
        body: payload,
      }
    )
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        if (data.errors) {
          reject(data);
        } else {
          resolve(data);
        }
      })
      .catch((err) => {
        reject(err);
      });
  });
};

// Create Property
export const createProperty = (payload: any) => {
  let _token: string | null = fetchCookie("1Q_SPA");
  return new Promise((resolve, reject) => {
    return fetch(`${process.env["REACT_APP_API_BASE_URL"]}/properties`, {
      // signal: signal,
      headers: {
        Authorization: `Bearer ${_token}`,
        "Content-Type": "application/json",
      },
      method: "POST",
      body: JSON.stringify(payload),
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        if (data.errors) {
          reject(data);
        } else {
          resolve(data);
        }
      })
      .catch((err) => {
        reject(err);
      });
  });
};
// Add Property Images
export const addProperImages = (
  id: string,

  payload: any
): Promise<BaseApiResponse<{}>> => {
  let _token: string | null = fetchCookie("1Q_SPA");
  return new Promise((resolve, reject) => {
    return fetch(
      `${process.env["REACT_APP_API_BASE_URL"]}/properties/${id}/add-images`,
      {
        headers: {
          Authorization: `Bearer ${_token}`,
        },
        method: "PUT",
        body: payload,
      }
    )
      .then((res) => {
        return res.json();
      })
      .then((data: BaseApiResponse<{}>) => {
        if (data.success) {
          resolve(data);
        } else {
          reject(data);
        }
      })
      .catch((err) => {
        reject(err);
      });
  });
};
// Delete A property
export const deleteProperty = (id: string): Promise<BaseApiResponse<{}>> => {
  let _token: string | null = fetchCookie("1Q_SPA");
  return new Promise((resolve, reject) => {
    return fetch(`${process.env["REACT_APP_API_BASE_URL"]}/properties/${id}`, {
      headers: {
        Authorization: `Bearer ${_token}`,
        "Content-Type": "application/json",
      },
      method: "DELETE",
      // body: payload,
    })
      .then((res) => {
        return res.json();
      })
      .then((data: BaseApiResponse<{}>) => {
        if (data.success) {
          resolve(data);
        } else {
          reject(data);
        }
      })
      .catch((err) => {
        reject(err);
      });
  });
};
// Delete A property
export const toggleVisibility = (id: string): Promise<BaseApiResponse<{}>> => {
  let _token: string | null = fetchCookie("1Q_SPA");
  return new Promise((resolve, reject) => {
    return fetch(
      `${process.env["REACT_APP_API_BASE_URL"]}/properties/${id}/toggle-visibility`,
      {
        headers: {
          Authorization: `Bearer ${_token}`,
          "Content-Type": "application/json",
        },
        method: "GET",
        // body: payload,
      }
    )
      .then((res) => {
        return res.json();
      })
      .then((data: BaseApiResponse<{}>) => {
        if (data.success) {
          resolve(data);
        } else {
          reject(data);
        }
      })
      .catch((err) => {
        reject(err);
      });
  });
};
// Update A property
export const updateProperty = (
  id: string | undefined,
  payload: any
): Promise<BaseApiResponse<{}>> => {
  let _token: string | null = fetchCookie("1Q_SPA");
  return new Promise((resolve, reject) => {
    return fetch(`${process.env["REACT_APP_API_BASE_URL"]}/properties/${id}`, {
      headers: {
        Authorization: `Bearer ${_token}`,
        "Content-Type": "application/json",
      },
      method: "PUT",
      body: JSON.stringify(payload),
    })
      .then((res) => {
        return res.json();
      })
      .then((data: BaseApiResponse<{}>) => {
        if (data.success) {
          resolve(data);
        } else {
          reject(data);
        }
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getInsuranceCompanies = () => {
  let _token: string | null = fetchCookie("1Q_SPA");
  return new Promise((resolve, reject) => {
    return fetch(
      `${process.env["REACT_APP_API_BASE_URL"]}/insurance-companies`,
      {
        // signal: signal,
        headers: {
          Authorization: `Bearer ${_token}`,
          "Content-Type": "application/json",
        },
        method: "GET",
      }
    )
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

// create iBuy property
export const createIBuyProperty = (payload: any) => {
  let _token: string | null = fetchCookie("1Q_SPA");
  return new Promise((resolve, reject) => {
    return fetch(
      `${process.env["REACT_APP_API_BASE_URL"]}/properties/ibuy-listing`,
      {
        // signal: signal,
        headers: {
          Authorization: `Bearer ${_token}`,
          "Content-Type": "application/json",
        },
        method: "POST",
        body: JSON.stringify(payload),
      }
    )
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        if (data.errors) {
          reject(data);
        } else {
          resolve(data);
        }
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const sendOTP = (signal: AbortSignal) => {
  let _token: string | null = fetchCookie("1Q_SPA");
  return new Promise((resolve, reject) => {
    return fetch(`${process.env["REACT_APP_API_BASE_URL"]}/auth/send-otp/`, {
      signal: signal,
      headers: {
        Authorization: `Bearer ${_token}`,
        "Content-Type": "application/json",
      },
      method: "GET",
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        if (data.errors) {
          reject(data);
        } else {
          resolve(data);
        }
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const validateOTP = (
  payload: { otp: string },
  signal: AbortSignal
): Promise<BaseApiResponse<{}>> => {
  let _token: string | null = fetchCookie("1Q_SPA");
  return new Promise((resolve, reject) => {
    return fetch(
      `${process.env["REACT_APP_API_BASE_URL"]}/auth/verify-phone/`,
      {
        signal: signal,
        headers: {
          Authorization: `Bearer ${_token}`,
          "Content-Type": "application/json",
        },
        method: "POST",
        body: JSON.stringify(payload),
      }
    )
      .then((res) => {
        return res.json();
      })
      .then((data: BaseApiResponse<{}>) => {
        if (data.success) {
          resolve(data);
        } else {
          reject(data);
        }
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getListings = (
  id: string,
  options: { [x: string]: string },
  signal: AbortSignal
) => {
  let _token: string | null = fetchCookie("1Q_SPA");
  let query = Object.keys(options)
    .map((key) => key + "=" + options[key])
    .join("&");
  return new Promise((resolve, reject) => {
    return fetch(
      `${process.env["REACT_APP_API_BASE_URL"]}/users/${id}/properties?${query}`,
      {
        signal: signal,
        headers: {
          Authorization: `Bearer ${_token}`,
          "Content-Type": "application/json",
        },
        method: "GET",
      }
    )
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const login = async (email: string, password: string) => {
  return await axios
    .post(`${url}/auth/login`, { email, password })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      throw err.response.data.errors[0].message;
    });
};

export const logout = (signal: AbortSignal) => {
  let _token: string | null = fetchCookie("1Q_SPA");
  return new Promise((resolve, reject) => {
    return fetch(`${process.env["REACT_APP_API_BASE_URL"]}/auth/logout`, {
      signal: signal,
      headers: {
        Authorization: `Bearer ${_token}`,
        "Content-Type": "application/json",
      },
      method: "GET",
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const register = async (data: {}) => {
  return await axios
    .post(`${url}/auth/register`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      throw err.response.data.errors[0].message;
    });
};

// request form end point
export const requestForm = async (data: {}) => {
  return await axios
    .post(`${url}/requests`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      throw err.response.data.errors[0].message;
    });
};

export const verifyEmail = async (id: any) => {
  return await axios
    .get(`${url}/auth/verify-email/${id}`)
    .then((res) => {
      // console.log(res);
      return res.data;
    })
    .catch((err) => {
      console.log(err);
      if (err?.response?.data?.message) throw err?.response?.data?.message;
      throw err?.response?.data?.errors[0].message;
    });
};
export const verifyNiesv = async (niesvNumber: any) => {
  return await axios
    .post(`${url}/auth/verify-niesv-number`, { niesvNumber: niesvNumber })
    .then((res) => {
      // console.log(res);
      return res.data;
    })
    .catch((err) => {
      console.log(err);
      if (err?.response?.data?.message) throw err?.response?.data?.message;
      throw err?.response?.data?.errors[0].message;
    });
};
export const verifyAean = async (aeanNumber: any) => {
  return await axios
    .post(`${url}/auth/verify-aean-number`, { aeanNumber: aeanNumber })
    .then((res) => {
      // console.log(res);
      return res.data;
    })
    .catch((err) => {
      console.log(err);
      if (err?.response?.data?.message) throw err?.response?.data?.message;
      throw err?.response?.data?.errors[0].message;
    });
};

export const reActivateAccount = async (id: any) => {
  return await axios
    .get(`${url}/auth/resend-verification-email/${id}`)
    .then((res) => {
      // console.log(res);
      return res.data;
    })
    .catch((err) => {
      console.log(err);
      if (err?.response?.data?.message) throw err?.response?.data?.message;
      throw err?.response?.data?.errors[0].message;
    });
};

export const changePasswordReq = (
  id: string,
  signal: AbortSignal,
  payload: ChangePasswordRequest
): Promise<BaseApiResponse<{}>> => {
  let _token: string | null = fetchCookie("1Q_SPA");
  return new Promise((resolve, reject) => {
    return fetch(
      `${process.env["REACT_APP_API_BASE_URL"]}/users/change-password/${id}`,
      {
        signal: signal,
        headers: {
          Authorization: `Bearer ${_token}`,
          "Content-Type": "application/json",
        },
        method: "PUT",
        body: JSON.stringify(payload),
      }
    )
      .then((res) => {
        return res.json();
      })
      .then((data: BaseApiResponse<{}>) => {
        if (data.success) {
          resolve(data);
        } else {
          reject(data);
        }
      })
      .catch((err) => {
        reject(err);
      });
  });
};

// Create Pin
export const createPin = (
  signal: AbortSignal,
  payload: CreatePinRequest
): Promise<BaseApiResponse<{}>> => {
  let _token: string | null = fetchCookie("1Q_SPA");
  return new Promise((resolve, reject) => {
    return fetch(`${process.env["REACT_APP_API_BASE_URL"]}/users/create-pin`, {
      signal: signal,
      headers: {
        Authorization: `Bearer ${_token}`,
        "Content-Type": "application/json",
      },
      method: "POST",
      body: JSON.stringify(payload),
    })
      .then((res) => {
        return res.json();
      })
      .then((data: BaseApiResponse<{}>) => {
        if (data.success) {
          resolve(data);
        } else {
          reject(data);
        }
      })
      .catch((err) => {
        reject(err);
      });
  });
};

// Change Pin
export const changePin = (
  id: string,
  signal: AbortSignal,
  payload: ChangePinRequest
): Promise<BaseApiResponse<{}>> => {
  let _token: string | null = fetchCookie("1Q_SPA");
  return new Promise((resolve, reject) => {
    return fetch(
      `${process.env["REACT_APP_API_BASE_URL"]}/users/change-pin/${id}`,
      {
        signal: signal,
        headers: {
          Authorization: `Bearer ${_token}`,
          "Content-Type": "application/json",
        },
        method: "PUT",
        body: JSON.stringify(payload),
      }
    )
      .then((res) => {
        return res.json();
      })
      .then((data: BaseApiResponse<{}>) => {
        if (data.success) {
          resolve(data);
        } else {
          reject(data);
        }
      })
      .catch((err) => {
        reject(err);
      });
  });
};

// get categories
export const getCategories = (signal: AbortSignal) => {
  let _token: string | null = fetchCookie("1Q_SPA");
  return new Promise((resolve, reject) => {
    return fetch(`${process.env["REACT_APP_API_BASE_URL"]}/categories`, {
      signal: signal,
      headers: {
        Authorization: `Bearer ${_token}`,
        "Content-Type": "application/json",
      },
      method: "GET",
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const tempDelete = (
  user: string,
  // signal: AbortSignal
  payload: any
): Promise<BaseApiResponse<NotificationsInterfaceResponse[]>> => {
  let _token: string | null = fetchCookie("1Q_SPA");
  return new Promise((resolve, reject) => {
    return fetch(`${process.env["REACT_APP_API_BASE_URL"]}/kinds/${user}`, {
      // signal: signal,
      headers: {
        Authorization: `Bearer ${_token}`,
        "Content-Type": "application/json",
      },
      method: "PUT",
      body: JSON.stringify(payload),
    })
      .then((res) => {
        return res.json();
      })
      .then((data: BaseApiResponse<NotificationsInterfaceResponse[]>) => {
        if (data.success) {
          resolve(data);
        } else {
          reject(data);
        }
      })
      .catch((err) => {
        reject(err);
      });
  });
};

// KYC
export const getPersonalKycStatus = async () => {
  try {
    const resp = await customFetch.get("/users/kyc?type=PERSONAL");
    return resp?.data?.data;
  } catch (error: any) {
    toast.error(error?.response?.data?.errors[0]?.message);
  }
};
export const getAgentKycStatus = async () => {
  try {
    const agentResp = await customFetch.get("/users/kyc?type=AGENT_LICENCE");
    return agentResp?.data?.data;
  } catch (error: any) {
    toast.error(error?.response?.data?.errors[0]?.message);
  }
};

// Search Property by key word
export const searchPropByKey = async (keyword: string, page: number = 1) => {
  try {
    const agentResp = await customFetch.get(
      `/listing/search?key=${keyword}&limit=20&page=${page}`
    );
    return agentResp?.data;
  } catch (error: any) {
    toast.error(error?.response?.data?.errors[0]?.message);
  }
};
export const searchPropertiesByKeyword = async (
  keyword?: string,
  startDate?: string,
  endDate?: string,
  page: number = 1
) => {
  try {
    const queryParams = new URLSearchParams();

    if (keyword) queryParams.append("key", keyword);
    if (startDate) queryParams.append("startDate", startDate);
    if (endDate) queryParams.append("endDate", endDate);
    queryParams.append("limit", "20");
    queryParams.append("page", page.toString());

    const agentResp = await customFetch.get(
      `/properties/available?${queryParams.toString()}`
    );
    return agentResp?.data;
  } catch (error: any) {
    toast.error(
      error?.response?.data?.errors?.[0]?.message ||
        "An error occurred while fetching properties."
    );
  }
};

// Bank Transfer
export const bankTransferPayment = async () => {
  try {
    const res = await customFetch.get(`/transactions/account-details/`);
    return res.data;
  } catch (error: any) {
    toast.error(error?.response?.data?.errors[0]?.message);
  }
};

// Upload Payment proof
export const uploadPaymentProof = async (
  id: string,
  data: FormData
): Promise<AxiosResponse<TransactionResponse>> => {
  try {
    const res = await customFetch.put(
      `/transactions/${id}/payment-proof`,
      data
    );

    return res;
  } catch (error: any) {
    toast.error(error?.response?.data?.errors[0]?.message);
    throw error;
  }
};

export const sendAmenitiesChecklist = (transactionId: string, payload: any) => {
  let _token: string | null = fetchCookie("1Q_SPA");
  return new Promise((resolve, reject) => {
    return fetch(
      `${process.env["REACT_APP_API_BASE_URL"]}/transactions/${transactionId}/amenities-checklist`,
      {
        headers: {
          Authorization: `Bearer ${_token}`,
          "Content-Type": "application/json",
        },
        method: "PUT",
        body: JSON.stringify({
          amenitiesChecklist: payload,
        }),
      }
    )
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        if (data.errors) {
          reject(data);
        } else {
          resolve(data);
        }
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const setDamagesCover = (transactionId: string, payload: any) => {
  let _token: string | null = fetchCookie("1Q_SPA");
  return new Promise((resolve, reject) => {
    return fetch(
      `${process.env["REACT_APP_API_BASE_URL"]}/transactions/${transactionId}/cover-damages`,
      {
        headers: {
          Authorization: `Bearer ${_token}`,
          "Content-Type": "application/json",
        },
        method: "PUT",
        body: JSON.stringify(payload),
      }
    )
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        if (data.errors) {
          reject(data);
        } else {
          resolve(data);
        }
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const addReferences = (transactionId: string, payload: any) => {
  let _token: string | null = fetchCookie("1Q_SPA");
  return new Promise((resolve, reject) => {
    return fetch(
      `${process.env["REACT_APP_API_BASE_URL"]}/transactions/${transactionId}/references`,
      {
        headers: {
          Authorization: `Bearer ${_token}`,
          "Content-Type": "application/json",
        },
        method: "PUT",
        body: JSON.stringify(payload),
      }
    )
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        if (data.errors) {
          reject(data);
        } else {
          resolve(data);
        }
      })
      .catch((err) => {
        reject(err);
      });
  });
};

// export const addReferences = (transactionId: string, payload: any) => {
//   let _token: string | null = fetchCookie("1Q_SPA");
//   return new Promise((resolve, reject) => {
//     return fetch(
//       `${process.env["REACT_APP_API_BASE_URL"]}/transactions/${transactionId}/references`,
//       {
//         headers: {
//           Authorization: `Bearer ${_token}`,
//           "Content-Type": "application/json",
//         },
//         method: "PUT",
//         body: JSON.stringify(payload),
//       }
//     )
//       .then((res) => {
//         return res.json();
//       })
//       .then((data) => {
//         if (data.errors) {
//           reject(data);
//         } else {
//           resolve(data);
//         }
//       })
//       .catch((err) => {
//         reject(err);
//       });
//   });
// };
export const addStaycation = (payload: any, signal?: AbortSignal) => {
  return new Promise((resolve, reject) => {
    fetch(`${process.env["REACT_APP_API_BASE_URL"]}/staycations`, {
      signal: signal,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getLegalSigningLink = (
  transactionId: string,
  signal: AbortSignal
) => {
  let _token: string | null = fetchCookie("1Q_SPA");

  return new Promise((resolve, reject) => {
    return fetch(
      `${process.env["REACT_APP_API_BASE_URL"]}/transactions/${transactionId}/legal-signing-link `,
      {
        signal: signal,
        headers: {
          Authorization: `Bearer ${_token}`,
          "Content-Type": "application/json",
        },
        method: "GET",
      }
    )
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        if (data.errors) {
          reject(data);
        } else {
          resolve(data);
        }
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const addLegalDoc = (id: string, payload: any) => {
  let _token: string | null = fetchCookie("1Q_SPA");
  return new Promise((resolve, reject) => {
    return fetch(
      `${process.env["REACT_APP_API_BASE_URL"]}/transactions/${id}/landlord-legal-details`,
      {
        headers: {
          Authorization: `Bearer ${_token}`,
          "Content-Type": "application/json",
        },
        method: "PUT",
        body: JSON.stringify(payload),
      }
    )
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        if (data.errors) {
          reject(data);
        } else {
          resolve(data);
        }
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export const updateAgentAvailabiltyProp = (
  propertyId: string,
  payload: any
) => {
  let _token: string | null = fetchCookie("1Q_SPA");
  return new Promise((resolve, reject) => {
    return fetch(
      `${process.env["REACT_APP_API_BASE_URL"]}/properties/${propertyId}/availability-calendar`,
      {
        headers: {
          Authorization: `Bearer ${_token}`,
          "Content-Type": "application/json",
        },
        method: "PUT",
        body: JSON.stringify(payload),
      }
    )
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        if (data.errors) {
          reject(data);
        } else {
          resolve(data);
        }
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export const getPropAvailabiltyCal = (
  propertyId: string,
  startDate: string,
  endDate: string
) => {
  let _token: string | null = fetchCookie("1Q_SPA");
  return new Promise((resolve, reject) => {
    return fetch(
      `${process.env["REACT_APP_API_BASE_URL"]}/properties/${propertyId}/availability-calendar?startDate=${startDate}&endDate=${endDate}`,
      {
        headers: {
          Authorization: `Bearer ${_token}`,
          "Content-Type": "application/json",
        },
        method: "GET",
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data.errors) {
          reject(data);
        } else {
          resolve(data);
        }
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export const putPropOnHold = (transactionId: string) => {
  let _token: string | null = fetchCookie("1Q_SPA");
  return new Promise((resolve, reject) => {
    return fetch(
      `${process.env["REACT_APP_API_BASE_URL"]}/transactions/${transactionId}/hold`,
      {
        headers: {
          Authorization: `Bearer ${_token}`,
          "Content-Type": "application/json",
        },
        method: "GET",
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data.errors) {
          reject(data);
        } else {
          resolve(data);
        }
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export const resumeTransaction = (transactionId: string) => {
  let _token: string | null = fetchCookie("1Q_SPA");
  return new Promise((resolve, reject) => {
    return fetch(
      `${process.env["REACT_APP_API_BASE_URL"]}/transactions/${transactionId}/resume`,
      {
        headers: {
          Authorization: `Bearer ${_token}`,
          "Content-Type": "application/json",
        },
        method: "GET",
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data.errors) {
          reject(data);
        } else {
          resolve(data);
        }
      })
      .catch((err) => {
        reject(err);
      });
  });
};
