import React, { useState } from "react";
import { format } from "date-fns";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const CustomDatePicker: React.FC<{
  checkInDate: Date | null;
  setCheckInDate: (date: Date | null) => void;
  checkOutDate: Date | null;
  setCheckOutDate: (date: Date | null) => void;
}> = ({ checkInDate, setCheckInDate, checkOutDate, setCheckOutDate }) => {
  const [showCalendar, setShowCalendar] = useState(false);
  const [dateRange, setDateRange] = useState<[Date | null, Date | null]>([
    checkInDate,
    checkOutDate,
  ]);
  const [startDate, endDate] = dateRange;

  return (
    <div className="position-relative w-100 mx-auto">
      <input
        type="text"
        placeholder="Check-in - Check-out"
        value={
          startDate && endDate
            ? `${format(startDate, "yyyy-MM-dd")} - ${format(
                endDate,
                "yyyy-MM-dd"
              )}`
            : ""
        }
        onClick={() => setShowCalendar(true)}
        readOnly
        className="form-control"
      />
      {showCalendar && (
        <div
          className="position-absolute bg-white border rounded shadow p-3 mt-2"
          style={{ zIndex: 1050 }}
        >
          <DatePicker
            selected={startDate}
            onChange={(update) => {
              const [newCheckIn, newCheckOut] = update as [
                Date | null,
                Date | null
              ];
              setDateRange([newCheckIn, newCheckOut]);
              setCheckInDate(newCheckIn);
              setCheckOutDate(newCheckOut);
              if (newCheckOut) setShowCalendar(false);
            }}
            startDate={startDate}
            endDate={endDate}
            selectsRange
            inline
          />
          <button
            className="mt-2 btn btn-search rounded-pill"
            onClick={() => setShowCalendar(false)}
          >
            Close
          </button>
        </div>
      )}
    </div>
  );
};

export default CustomDatePicker;
