import React, { useEffect, useRef } from "react";

declare global {
  interface Window {
    bootstrap: any;
  }
}

type ModalProps = {
  id?: string;
  show: boolean;
  onClose: Function;
  size?: "sm" | "md" | "lg" | number;
  position?: "top" | "center";
  children?: JSX.Element | JSX.Element[];
  dismissible?: boolean;
};

export const Modal = ({
  children,
  show,
  onClose,
  id,
  size = "md",
  position = "center",
  dismissible: dismissable = true,
}: ModalProps) => {
  const modalRef = useRef<HTMLDivElement>(null);
  const modalInstanceRef = useRef<any>(null);
  const modalSize = size === "md" ? "" : `modal-${size}`;
  const uniqueId = useRef(
    `modal-${Math.random().toString(36).substring(2, 11)}`
  );
  const modalId = id || uniqueId.current;

  // Initialize modal only once
  useEffect(() => {
    if (modalRef.current) {
      modalInstanceRef.current = new window.bootstrap.Modal(modalRef.current, {
        keyboard: false,
        backdrop: dismissable ? true : "static",
        focus: false,
      });

      // Add event listener for when modal is hidden
      modalRef.current.addEventListener("hidden.bs.modal", () => {
        onClose();
      });
    }

    return () => {
      // Clean up properly when component unmounts
      if (modalRef.current) {
        modalRef.current.removeEventListener("hidden.bs.modal", () =>
          onClose()
        );
      }

      if (modalInstanceRef.current) {
        modalInstanceRef.current.dispose();
      }
    };
  }, []);

  // Handle show/hide based on prop changes
  useEffect(() => {
    if (!modalInstanceRef.current) return;

    if (show) {
      modalInstanceRef.current.show();
    } else {
      modalInstanceRef.current.hide();
    }
  }, [show]);

  return (
    <div
      className={`modal fade ${typeof size === "string" ? modalSize : ""}`}
      id={modalId}
      tabIndex={-1}
      ref={modalRef}
      aria-labelledby="modalLabel"
      aria-hidden="true"
    >
      <div
        className={`modal-dialog ${
          position === "center" ? "modal-dialog-centered" : ""
        }`}
        style={typeof size === "number" ? { maxWidth: size } : undefined}
      >
        <div className="modal-content">
          <div className="modal-header border-0">
            {dismissable && (
              <button
                type="button"
                className="btn-close fs-12"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            )}
          </div>
          <div className="modal-body">{children}</div>
        </div>
      </div>
    </div>
  );
};
